.input-wrapper {
    position: relative;
    width: 100%;
}

.input-wrapper svg {
    position: absolute;
    fill: var(--inputLabelColor);
    height: 55%;
    transform: translate(0%, -50%);
    top: 50%;
    right: 0px;
    cursor: pointer;
}

.input-wrapper svg::before {
    content: "";
    position: absolute;
    background-color: red;
    width: 500px;
    height: 500px;
    top: 0;
    left: 0;
}

.input {
    width: 100%;
    height: 45px !important;
    box-sizing: border-box;
    border-radius: var(--inputBorderRadius);
    outline: none;
    padding: 0 12px;
    background: var(--inputBackground);
    color: var(--inputTextColor);
    border: var(--inputBorderWidth) var(--inputBorderColor) solid;
    font-size: 14px;
}

.input::-webkit-input-placeholder {
    opacity: 0;
}

.input-wrapper>label {
    position: absolute;
    transform: translate(0, -50%);
    top: 50%;
    left: 14px;
    font-size: 16px;
    transition: .3s;
    pointer-events: none;
    color: var(--inputLabelColor);

}

.input-wrapper>label:before {
    content: "";
    position: absolute;
    transform: translate(-50%, -50%);
    top: 65%;
    left: 50%;
    width: calc(100% + 15px);
    height: calc(var(--inputBorderWidth) + 8px);
    background-color: var(--background);
    z-index: -1;
    opacity: 0;
}
/* 
.input-wrapper::-moz-placeholder, .input-wrapper:placeholder-shown {
    display: none !important;
    opacity: 0 !important;
} */

.input-wrapper .input:-webkit-autofill {
    -webkit-text-fill-color: #fff !important;

}

.input-wrapper:has(input:-webkit-autofill) > label {
    position: absolute;
    top: -5%;
    left: 19px;
    font-size: 14px;
    transition: .3s;
}

.input-wrapper:has(input:-webkit-autofill) label::before {
    opacity: 1;
}

.input-wrapper.focus label{
    position: absolute;
    top: -5%;
    left: 19px;
    font-size: 14px;
    transition: .3s;
}

.input-wrapper.focus label::before {
    opacity: 1;
}

@media screen and (max-width: 391px) {
    .input {
        height: 60px;
    }
}