#profile-page-wrapper {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: #fafafa;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 9;
    padding: 0 0 15px 0;
}

.editSvg {
    cursor: pointer;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    fill: transparent;
    position: absolute;
}

img {
    width: 100%;
    height: 100%;
}

.profilePageContainer {
    width: calc(100vw - 90px);
    max-width: 1250px;
    margin-left: 70px;
}

#profile-page-header {
    width: 100%;

    /* emin değilim */
    height: 390px;
    /* emin değilim */

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    background-position: flex-start;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border: 1px solid #EDE9E9;
}

.profile-nav-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    overflow-x: visible;
}

.profile-nav-wrapper .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer button {
    color: #515365;
}

.profile-nav-wrapper .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer button.Mui-selected {
    color: #FA6342;
    padding-bottom: 27px;
    padding-top: 29px;
}

.profile-nav-wrapper .MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
    background: transparent;
    overflow-y: scroll;
    height: 15px;
}

.MuiBox-root.css-qh29qi {
    max-width: 100%;
    /* width: auto; */
}

.profile-nav-wrapper .MuiTabs-indicator.css-1aquho2-MuiTabs-indicator::before {
    content: "";
    background: url("./assets/up_triangle.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    display: block;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
}

#profile-banner-wrapper {
    border-left: 2px solid #fff;
    border-right: 2px solid #fff;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 1244px;
    max-height: 319px;
}

#profile-banner.my:hover {
    filter: blur(2px);
    transition: .3s;
}

#profile-banner+svg {
    pointer-events: none;
}

#profile-banner-wrapper.my:hover>svg {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    z-index: 99;
    fill: #FA6342;
    transition: .3s;
}

#profile-banner-wrapper.my:has(.profilePicWrapper:hover)>svg {
    fill: transparent;
}

#profile-banner {
    width: 100%;
    aspect-ratio: 3.9;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    z-index: -1;
}


#profile-page-header-end {
    width: 100%;
    height: 70px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* padding: 0 0 0 50px; */
    align-items: center;
    position: relative;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    /* box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px; */
}

#posts-follow-counts-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;

    /* margin-left: 150px; */
}

#posts-follow-counts-wrapper div[class*="count-wrapper"] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#posts-follow-counts-wrapper div[class*="count-wrapper"] h1 {
    color: #515365;
    font-size: 14px;
    font-weight: 500;
}

#posts-follow-counts-wrapper div[class*="count-wrapper"] p {
    color: #FA6342;
    font-size: 14px;
    font-weight: 500;

}

#profile-name {
    text-transform: capitalize;
    font-size: 20px;
    color: #333333;
    white-space: nowrap;
}

#profile-locality-p {
    font-size: 12px;
    color: #515365;
    width: max-content;
}

#profilePhotoAndName {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 100%;
}

#profileNameAndActions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: auto;
    margin-left: calc(80px);
    transform: translateX(-50%)
}

#fallowAndMessageWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    transition: .3s;
    position: absolute;
    bottom: 71px;
    border-radius: 5px;
    height: 54px;
    padding: 7px 11px;
    right: 0;
    z-index: 2;
    background: #ffffffab
}

#profile-page-body {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: 1250px;
    margin-top: 15px;
}

#profile-actions-wrapper {
    position: absolute;
    top: 50%;
    left: 28%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.profile-actions-tooltip-wrapper {
    aspect-ratio: 1;
    height: 100%;
    max-height: 32px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profile-actions-tooltip-wrapper>button {
    height: 100%;
    aspect-ratio: 1;
    background-color: rgba(173, 173, 253, 0.66);
    border-radius: 50%;
    border: none;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.20) 0px 1px 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profile-actions-tooltip-wrapper>button>svg {
    width: 75%;
    aspect-ratio: 1;
    fill: #fff;
    transform: rotateZ(90deg);
}

.profile-actions-tooltip-wrapper>button.active {
    background-color: aliceblue;
    box-shadow: rgba(0, 0, 0, 0.20) 0px 1px 3px;
}

.profile-actions-tooltip-body {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 7px;
    opacity: 0;
    transition: .3s;
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-60%, -95%);
    border-radius: 15px;
    overflow: hidden;
}

.profile-actions-tooltip-body.active {
    opacity: 1;
    transition: .3s;
    transform: translate(-60%, -105%);
    top: 0;
    left: 50%;
    z-index: 99;
}

.profile-actions-tooltip-body>button {
    background-color: aliceblue;
    padding: 15px 20px;
    width: 100%;
    white-space: nowrap;
    border: none;
    transition: .3s;
    cursor: pointer;
}

.profile-actions-tooltip-body>button:hover {
    background-color: rgb(87, 163, 230);
    transition: .3s;
}


.follow-action-warapper {
    background: rgb(87, 163, 230);
    border: 1px solid rgb(87, 163, 230);
    border-radius: 100px;
    aspect-ratio: 1;
    height: 40px;
    color: #fff;
    transition: .3s;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.follow-action-warapper>button {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
}

.follow-action-warapper>svg {
    fill: #fff;
    height: 17px;
    width: auto;
}


.follow-action-warapper.followed {
    background: #fff;
    border: 1px solid rgb(87, 163, 230);
    color: rgb(87, 163, 230);
    transition: .3s;
}

.follow-action-warapper.followed>button {
    color: rgb(87, 163, 230);
}

.follow-action-warapper.followed>svg {
    fill: rgb(87, 163, 230);
}



.message-action-warapper {
    background: rgb(226, 171, 108);
    border: 1px solid rgb(226, 171, 108);
    border-radius: 100px;
    aspect-ratio: 1;
    height: 40px;
    color: #fff;
    transition: .3s;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.message-action-warapper>button {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    color: #fff;
    cursor: pointer;

}

.message-action-warapper>svg {
    fill: #fff;
    height: 22px;
    width: 22px;
}

/* 
.message-action-warapper.disabled {
    background: #fff;
    border: 1px solid rgb(87, 163, 230);
    color: rgb(87, 163, 230);
    transition: .3s;
}

.message-action-warapper.disabled>button {
    color: rgb(87, 163, 230);
}

.message-action-warapper.disabled>svg {
    fill: rgb(87, 163, 230);
} */


.profilePicWrapper {
    height: 160px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 5px solid #fff;
    position: absolute;
    bottom: 10px;
    left: 50px;
    transform: translateY(0%);
    z-index: 80;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: .3s;
    cursor: pointer;
    z-index: 89;
}

.profilePicWrapper.my img:hover {
    filter: blur(2px);
    transition: .3s;
    position: relative;
}

.profilePicWrapper.my:hover>svg {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    z-index: 999;
    fill: #FA6342;
    pointer-events: none;
    transition: .3s;
}


#pictureSelectorWrapper {
    position: absolute;
    bottom: 0;
    right: -15px;
}

.profilePicWrapper img {
    width: 100%;
    height: 100%;
}

/* page close button */
#page-close {
    width: 50px;
    height: 50px;
    background: transparent;
    border: none;
    position: absolute;
    top: 70px;
    right: 0;
    cursor: pointer;
    transition: .3s;
    z-index: 99;
}

#page-close:hover {
    transform: scale(.8);
    transition: .3s;
}

#page-close>svg {
    fill: gray;
}

#page-close:hover>svg {
    fill: rgb(58, 58, 58);
}

/* page close button */


#my-posts-side {
    max-width: 500px;
    width: 100%;
}

#my-best-posts-side {
    max-width: 500px;
    width: 100%;
}

/* .postsScrollView {
    height: 53vh;
    scroll-snap-type: mandatory;
    scroll-snap-points-y: repeat(300px);
    scroll-snap-type: y mandatory;
    
} */

/* .first-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    width: 100%;
} */

#bio-side {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.bio-side-box {
    width: 100%;
    height: 100%;
    background: #fff;
    border: 1px solid #EDE9E9;
    padding: 7px 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.bio-side-box.left {
    width: 33%;
}

.bio-side-box.right {
    width: 66%;
}

.bio-side-box.right .textAreaWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    gap: 20px;
}

.bio-title {
    font-size: 15px;
    font-weight: 500;
    color: #515365;
}

#profile-page-body>.second-row {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: max-content;
    gap: 15px;
}

#profile-page-body>.second-row .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 15px;
}


.titleRow {
    width: 100%;
    position: relative;
    padding: 11px 6px;
    margin-bottom: 18px;
}

.titleRow .right {
    display: block;
    width: 3px;
    height: 24px;
    background: #fa6442;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%)
}


.titleRow .bottom {
    display: block;
    width: calc(100% - 16px);
    height: 1px;
    background: #EDE9E9;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%)
}

.icon-and-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.icon-and-title h1 {
    font-size: 14px;
    color: #515365;
    margin-left: 8px;
    font-weight: 500;
}

.icon-and-title svg {}

.bio-textarea {
    resize: none;
    width: 100% !important;
    border: none;
    border-radius: 5px;
    padding: 7px 0;
    background: transparent;
    color: #515365;
    cursor: default;
    font-size: 14px;
    font-weight: 500;
    font-family: inter, sans-serif;
    transition: .2s;
}

.bio-textarea:focus-visible {
    outline-style: none;
}


.bio-textarea.editable {
    cursor: revert;
    background: #E4E4E4;
    padding: 7px 6px;
    transition: .2s;
}

.editable-text-area-wrapper {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
}


.editButton {
    background: transparent;
    border: none;
    position: absolute;
    bottom: 0px;
    right: -4px;
    width: 30px;
    height: 30px;
    /* transform: translate(-50%, -50%); */
    border-radius: 100%;
    padding: 4px 4px 8px 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: .3s;
    /* border: 1px solid #000; */
}

.editButton.active {
    background: transparent;
}


.editButton.active:hover {
    background: #fa64423b;
    transition: .3s;
    /* transform: translate(-50%, -50%); */
}

.editButton.active>svg {
    fill: #fa6442;
}

.editButton:hover {
    background: #fa64423b;
    transition: .3s;
    /* transform: translate(-50%, -50%); */
}

.editButton>svg {
    width: 100%;
    height: 100%;
    fill: #fa6442
}



.profile-nav-buttons {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    /* gap: 5px; */
}

.profile-nav-buttons>button {
    height: 50px;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border: none;
    padding: .5em 2em;
    transition: .15s;
    cursor: pointer;
}

.profile-nav-buttons>button:hover {
    background: #2872b8a9;
    color: #fff;
}

.profile-nav-buttons>button.active {
    background: #2872b8;
    color: #fff;
    height: 62px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border: none;
    transition: .15s;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

/* 
button + .profile-nav-buttons>button.active {
    border-top-left-radius: 15px;
}
.profile-nav-buttons>button + button.active {
    border-top-left-radius: 15px;
} */

.premium-action-wrapper {
    background: gold;
    padding: 8px 15px;
    margin-left: 15px;
    border-radius: 100px;
    height: 40px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
}


.premium-action-wrapper>.getPremiumButton {
    border: none;
    background: transparent;
    cursor: pointer;
    color: #fff;
}

.premium-action-wrapper>svg {
    fill: #fff;
    height: 22px;
    width: 22px;
}





/* 
 */



.customSwitchWrapper {
    background-color: rgb(170, 162, 162);
    border-radius: 100px;
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px;
    position: relative;
}

.customSwitchButton {
    background: transparent;
    color: #fff;
    border-radius: 100px;
    border: none;
    width: 50%;
    height: 100%;
    padding: 8px 15px;
    z-index: 1;
}

.customSwitchButton.active {}

.customSwitchSelector {
    border-radius: 100px;
    border: none;
    width: auto;
    height: 100%;
    padding: 8px 15px;
    background: #1364a7;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 25%;
    width: 50%;
    transition: .3s;
}

.customSwitchSelector.right {
    border-radius: 100px;
    border: none;
    width: auto;
    height: 100%;
    padding: 8px 15px;
    background: #1364a7;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 75%;
    width: 50%;
    transition: .3s;
}


@media screen and (max-width: 1200px) {
    #profileNameAndActions {
        margin-left: 0;
        transform: translateX(0);
    }

    #fallowAndMessageWrapper {
        top: 0;
        right: calc(32px + 15px + 15px);
    }

    .profile-actions-tooltip-wrapper {
        position: absolute;
        top: 7px;
        right: 15px;
        width: 40px;
        height: 40px;
    }

    #profilePhotoAndName {
        justify-content: flex-start;
        align-items: flex-start;
    }

    #profile-page-header-end {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: auto;
        padding: 11px 15px;
    }

    .profile-nav-wrapper {
        width: 100%;
    }
}



@media only screen and (max-width: 1020px) {
    #profile-page-header-end {
        height: auto;
        flex-direction: column;
        align-items: flex-start;
    }

    .bio-side-box.right .textAreaWrapper {
        flex-direction: column;
    }

    #bio-side {
        flex-direction: column;
    }

    .bio-side-box.left {
        width: 100%;
        margin-bottom: 15px;
    }

    .bio-side-box.right {
        width: 100%;
    }

    .profilePicWrapper {
        transform: none;
        height: 100px;
    }

    .profile-actions-tooltip-wrapper>button {
        height: 100%;
    }

    .profile-nav-buttons {
        width: 100%;
    }


    #fallowAndMessageWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        /* width: 100%; */
        padding: 15px;
    }

    .follow-action-warapper {
        width: 45%;
    }

    .message-action-warapper {
        width: 45%;
    }

    .profile-nav-buttons>button {
        max-width: 25%;
        padding: 0;
        width: 100%;
    }



    #followersWrapper {
        width: 100% !important;
        margin: auto auto;
    }


}

@media only screen and (max-width: 800px) {
    .profilePageContainer {
        padding-bottom: calc(70px + 15px);
    }


    /* emin değilim */
    #profile-banner-wrapper {
        height: 90px;
    }
    #profile-page-header {
        height: unset;
    }
    /* emin değilim */

    .profilePageContainer {
        margin-left: 0;
        width: calc(100vw - 30px);
    }
    

}

@media only screen and (max-width: 600px) {
    #profile-page-body>.second-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .profile-actions-tooltip-body,
    .profile-actions-tooltip-body.active {
        left: 15%;
    }

    .profilePicWrapper {
        left: 0;
        height: 60px;
        border-width: 2px;
    }

    #profilePhotoAndName {}

    #profileNameAndActions {
        width: 100%;
    }

    .customSwitchWrapper {
        width: 95%;
    }

    .premium-action-wrapper {
        margin-left: 0;
    }

    .profile-nav-wrapper .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer button {
        color: #515365;
        font-size: 11px;
        padding: 0;
        min-width: min-content !important;
        min-height: min-content !important;
        background: #EDF2F6;
        border-radius: 100px;
        padding: 2px 2px
    }

    .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
        gap: 5px;

    }

    .profile-nav-wrapper .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer button.Mui-selected {
        padding: 0;
    }

    .MuiBox-root.css-tp0bqz {
        height: 100%
    }

    .profile-nav-wrapper {
        height: 20px;
        margin: 10px 0;
    }

    .MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
        display: none;
    }
}