#aboutContentWrapper {
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-bottom: 15px;
    border-radius: 5px;
    padding: 16px;
    transition: .3s;
    position: relative;
}

#aboutContentWrapper .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

#aboutContentWrapper .header .iconAndTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

#aboutContentWrapper .header h1 {
    font-size: 25px;
    font-weight: 500;
    color: #fff;
}

#aboutContentWrapper .header svg {
    height: 100%;
}

.aboutContentTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    text-transform: capitalize;
    color: #515365;
    padding: 10px 0;
}

.aboutParagraph {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    transition: .5s;
    font-size: 14px;
    line-height: 25px;
    padding: 13px 0 23px 0;
    max-height: 150px;
    overflow: hidden;
}

/* possible bug */
.aboutParagraph * {
}
/* ------------ */
.aboutParagraph.full {
    -webkit-line-clamp: unset;
    line-clamp: unset;
    transition: 1s;
    max-height: 500px;
}

.divider {
    height: 1px;
    width: 100%;
    background: rgba(255, 255, 255, 0.4);
    display: block;
}