#mobileMenu {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 0;
    transform: translateY(100%);
    background: #F3F3F3;
    z-index: -999;
    transition: .2s;
}

#mobileMenu.open {
    height: 150px;
    transition: .2s;
    z-index: 100;
}