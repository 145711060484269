@font-face {
    font-family: handWrite;
    src: url("./assets/fonts/DancingScript-VariableFont_wght.ttf");
}

@font-face {
    font-family: inter;
    src: url("./assets/fonts/Inter.ttf");
}

@font-face {
    font-family: space;
    src: url("./assets/fonts/Space.ttf");
}


#loginPage {
    background: url("./assets/login_bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#registerPage {
    background: url("./assets/login_bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#registerPage .border-bottom-date-wrapper {
    background: transparent;
}

#registerPage .setting-input {
    color: #fff;
}

#forgotPasswordPage {
    background: url("./assets/login_bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


#login-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 10px 20px 0 20px;
    background: #FA6342;
    position: absolute;
    max-width: 350px;
    top: 0;
    left: 225px;
}

/* VanillaDateInput sso için edit */

#login-container .border-bottom-date-wrapper {
    border-radius: 100px;
    width: 100%;
    min-height: 45px;
    border: 2px solid #fff;
}

#login-container .border-bottom-date-wrapper input::placeholder,
#login-container .border-bottom-date-wrapper span {
    color: #fff;
}

#login-container .border-bottom-date-wrapper svg path {
    fill: #fff;
}

/* ---------------------------------- */

#login-container.forgotPassword .button,
#login-container.register .button {
    width: 100%;
}
#login-container.forgotPassword .title {
    font-size: 22px;
}

#login-circle {
    position: absolute;
    transform: translate(-50%, 50%);
    bottom: 0;
    left: 50%;
    width: auto;
    aspect-ratio: calc(571px / 600px);
    height: calc((100vh - 500px) * 2 - (80px * 2));
    max-height: 651px;
    z-index: -1;
}

#login-container::after {
    content: "";
    position: absolute;
    background-image: url("./assets/login-triangle.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transform: translate(-50%, 75px);
    bottom: .5px;
    left: 50%;
    width: calc(100% + 2.5px);
    height: 76px;
}

#login-container .title {
    font-size: 38px;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

#login-container .title span {
    color: #50c16d;
    font-size: 60px;
    margin-left: 5px;
}

#register-container {
    max-width: 1240px;
    width: 100%;
    margin: 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 50px 0;
}

#register-container .title {
    font-family: handWrite;
    font-size: 38px;
    color: #e3f2ff;
}

#register-container .title span {
    color: #50c16d;
    font-size: 60px;
    margin-left: 5px;
}

#registerPage .form-wrapper {
    max-height: 350px;
    overflow-y: scroll;
}

#registerPage .form-wrapper::-webkit-scrollbar-thumb {
    background: #ffffff;
}

.form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 273px;
    width: 310px;

    padding: 20px 40px 0 40px;
    position: relative;
}

.register-buttons-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.form-wrapper .login-p {
    font-size: 13px;
    color: #fff;
    text-align: center;
    margin-bottom: 17px;
}

.form-wrapper span[class *="line"] {
    position: absolute;
    background: rgba(255, 255, 255, 0.25);
}

.form-wrapper .line1 {
    width: calc(100% - 60px);
    height: 1px;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
}

.form-wrapper .line2 {
    width: 1px;
    height: calc(100% - 60px);
    top: 50%;
    left: 0;
    transform: translate(0%, -50%);
}

.form-wrapper .line3 {
    width: 1px;
    height: calc(100% - 60px);
    top: 50%;
    right: 0;
    transform: translate(0%, -50%);
}


.form-wrapper>div {
    margin-bottom: 15px;
}


.stage-display-false {
    display: none !important;
}

.register-stage {}

.add-profile-stage .info {
    color: var(--inputTextColor);
    margin: 15px 0 5px 0;
    width: 280px;
}

.button {
    background: #fff;
    border: 1px solid #EAEAEA;
    width: auto;
    padding: 0 21px;
    height: 45px;
    border-radius: 100px;
    color: #515365;
    font-size: 13px;
    font-family: inter, sans-serif;
    font-weight: 700;
    cursor: pointer;
}


.socialLoginButton {
    background: transparent;
    border: none;
    cursor: pointer;
}


/* Auto Complete CSS */

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #FA6342 inset !important;
}

input:-webkit-autofill {
    -webkit-text-fill-color: var(--inputLabelColor) !important;
}

/* ----------------- */

.register-forgot-password-wraper {
    width: 98%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.register-forgot-password-wraper a {
    font-family: inter, sans-serif;
    font-weight: 300;
    font-size: 13px;
    padding: 3px 10px;
    color: #fff;
}

.register-forgot-password-wraper a:hover {
    border-radius: 15px;
    transition: .3s;
    color: #fff;
}

.register-forgot-password-wraper:has(a:nth-child(2)) {
    justify-content: space-between;
}



.buttonAndForgotPasswordWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px !important;
}


.ssoTextButton {
    font-size: 13px;
    color: #fff;
    font-weight: 500;
    white-space: nowrap;
}


#remember-me-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 45px;
}

.remember-me-text {
    font-size: 13px;
    color: #fff;
    height: 100%;
}

.singUpMethodsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 30px;
    margin-bottom: 0 !important;
}


.singUpMethodsWrapper img {
    width: 27px;
}

a {
    text-decoration: none;
    color: var(--buttonColor);
    /* font-family: handWrite; */
    font-size: 19px;
    font-weight: 600;
    font-family: Inter, sans-serif;
}

.mailLink {
    width: 30px;
    height: 30px;
    padding: 5px !important;
    border-radius: 100px;
    background: #e3f2ff !important;
}

.mailLink>svg {
    width: 100%;
    height: 100%;
}

.mailLink:hover {
    background: #1d90f5 !important;
}


.mailLink:nth-child(1)>svg>rect {
    /* For remove gmail svg background color */
    fill: transparent;
}

.gmailLinks {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.loginStatsWrapper {
    position: absolute;
    max-width: 450px;
    top: calc(.183 * 100vh);
    left: 50vw;
}

.loginStatsWrapper>img {
    width: 133px;
    height: auto;
    margin-bottom: 10px;
}

.loginStatsWrapper h1 {
    color: #fff;
    font-size: 40px;
    font-weight: 300;
}

.loginStatsWrapper p {
    color: #fff;
    font-size: 15px;
    font-weight: 300;
}

.statsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 45px;
    margin-top: 55px;
}

.statWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.statWrapper div {
    width: 45px;
    height: 45px;
    background: #FA6342;
    padding: 10px;
    border-radius: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


#loginStatsAndContainerWrapper {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 820px) {
    #login-container {
        align-items: center;
    }

    .form-wrapper {
        width: 100%;
        max-width: 350px;
    }

    #register-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0 20px;
    }

}

@media screen and (max-width: 1250px) {
    #loginStatsAndContainerWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 3vh;
    }

    #login-container {
        top: unset;
        bottom: unset;
        left: unset;
        transform: translateX(0);
        border-radius: 5px;
        position: relative;
    }

    .login-p {
        display: none;
    }

    #login-container::after {
        display: none;
    }

    #login-circle {
        display: none;
    }

    .statsWrapper {
        justify-content: center;
    }

    .loginStatsWrapper {
        width: 100%;
        position: relative;
        top: unset;
        left: unset;
        transform: translateX(0);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .loginStatsWrapper h1 {
        color: #fff;
        font-size: 30px;
        font-weight: 300;
        text-align: center;
    }

    .loginStatsWrapper p {
        color: #fff;
        font-size: 13px;
        font-weight: 300;
        text-align: center;
    }

    .statsWrapper {
        margin-top: 3vh;
    }

}

@media screen and (max-height: 738px),
(max-width: 300px) {

    .loginStatsWrapper h1,
    .loginStatsWrapper>p {
        display: none;
    }

    #login-container {}

}