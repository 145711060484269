.savedProfilesAndTitle {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 100%;

}

.savedProfileWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 100%;
    overflow-y: auto;
}

.savedProfileWrapper::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.savedProfileWrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0px 10px var(--inputLabelColor);
    border-radius: 5px;
}

/* Handle */
.savedProfileWrapper::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 5px;
    transition: .3s;
}

.savedProfileWrapper:hover::-webkit-scrollbar-thumb {
    background: #FA6342;
    transition: .2s;
}

.savedProfilesH1 {
    color: #FA6342;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.profilesWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px 0;
    border-bottom: 2px dashed #C4D6ED;
    cursor: pointer;
    transition: .3s;
    position: relative;
}

.profilesWrapper.selected {
    background: #c4d6ed57;
    border-radius: 5px;
    transition: .3s;
}

.profileWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.profileLabel {
    text-align: center;
    color: #515365;
    font-size: 13px;
    font-weight: 500;
    white-space: nowrap;
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.zodiacSignsWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 17px;
}

.zodiacSign {
    background: #23D2E2;
    width: 35px;
    height: 35px;
    aspect-ratio: 1;
    border-radius: 100%;
    padding: 5px;
}

.zodiacSign.feMale {
    background: #FC8DCA;
}

.zodiacSign svg {
    width: 100%;
    height: 100%;
}

.zodiacSign svg path {
    fill: #fff;
}