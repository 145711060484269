#tipsWrapper {
    width: 100%;
    height: 100%;
    z-index: 100;
    position: absolute;
    pointer-events: none;
}

#scrollAvailableTip {
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%) rotateZ(-50deg);
    animation: scrollTipAnimation 1.5s linear infinite;
}

#scrollAvailableTip>svg {
    width: 100%;
    height: 100%;
}

@keyframes scrollTipAnimation {
    0% {
        bottom: 0;
        transform: translate(-50%, -50%) rotateZ(-90deg);

    }

    60% {
        bottom: 100px;
        transform: translate(-50%, -50%) rotateZ(0deg);
    }

    100% {
        bottom: 0;
        transform: translate(-50%, -50%) rotateZ(-90deg);
    }

}