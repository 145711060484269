#side-menu-wrapper {
    max-width: 400px;
    width: 100vw;
    height: 100%;
    padding-top: 70px;
    position: absolute;
    background-color: rgba(16, 35, 49, 1);
    top: 0;
    left: 0;
    transform: translate(-100%, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 99;
    transition: .3s;
}

.side-menu-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 15px;
}

.side-menu-nav-buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
}

.side-menu-nav-buttons-wrapper> .nav-button-wrapper {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 50px;
    cursor: pointer;
}

.nav-button-wrapper.selected > button {
    border-bottom: 1px solid #fff !important;
    transition: .3s;
}

.side-menu-nav-buttons-wrapper> .nav-button-wrapper > button {
    background-color: transparent;
    border: none;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
}

.side-menu-nav-buttons-wrapper> .nav-button-wrapper > svg {
    width: auto;
    height: 20px;
    fill: #fff;
}


.side-menu-button {
    background-color: rgb(107, 161, 223);
    border-radius: 50%;
    border: none;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
    height: 35px;
    width: 35px;
    cursor: pointer;
}



.side-menu-button svg {
    fill: white;
    height: 50%;
    width: 50%;
    margin: 2px 0 0 2px;
    transform: translate(25%, 7%);
    transition: .3s;
}

.side-menu-button:not(button[class*="position-open"]):hover>svg {
    transform: translate(50%, 7%);
}

.side-menu-button.position-open svg {
    transform: rotateZ(180deg) translate(7%, -7%);
    transition: .3s;
}

#side-menu-wrapper[class*=position-open] {
    transform: translateX(0) !important;
}

#side-menu-wrapper[class*=position-close] {
    transform: translateX(-100%) !important;
}

.css-1lk7enj * {
    color: var(--inputLabelColor) !important;
}

.css-1lk7enj label {
    top: 0px !important;
}

.side-menu-form-wrapper>.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root>div {
    height: 56px !important;
}

.side-menu-form-wrapper>.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root label {
    top: 0 !important;
}


.switchAndLabel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.switchAndLabel label[class="active"] {
    color: var(--inputLabelColor);
}

.switchAndLabel label[class="passive"] {
    color: rgba(191, 210, 231, 0.5);
}

.scrollView {
    overflow-y: auto;
    max-height: 200px;
}

.MuiFormControl-root.MuiFormControl-fullWidth.css-q8hpuo-MuiFormControl-root>* {
    color: var(--inputLabelColor) !important;
}


.customButton {
    background: transparent;
    border: 1;

}


@media screen and (max-width: 780px) {
    .side-menu-button {
        display: none;
    }
}

@media screen and (max-width: 450px) {
    #side-menu-wrapper {
        max-width: 100%;
    }
}