#sideBarWrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 98;
}

#sideBarWrapper * {
    user-select: none;
}

#sideBar {
    width: 70px;
    height: calc(100vh);
    background: #fff;
    position: absolute;
    transform: translateX(-100%);
    top: 0;
    left: 0;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 85px;
    gap: 10px;
    transition: .3s;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 40px;
}

#sideBar.open {
    width: 70px;
    height: calc(100vh);
    background: #fff;
    transform: translateX(0%);
    transition: .3s;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 85px;
    gap: 10px;
}

#sideBar button {
    width: 40px;
    height: 40px;
    background: #54667D;
    border-radius: 100%;
    border: none;
    cursor: pointer;
  animation: toApear .2s linear forwards;
}
@keyframes toApear {
    0% {
        transform: scale(0)
    }
    100% {
        transform: scale(1)
    }
  }

#contentSideWrapper {
    top: 0;
    padding-top: 85px;
    left: 0;
    transform: translateX(-100%);
    height: 100%;
    width: min-content;
    background: #fff;
    transition: .3s;
}

#contentSideWrapper.open {
    transform: translateX(0%);
    transition: .3s;
    z-index: 99;
}


@media only screen and (max-width: 800px) {
    body {
        overflow-y: hidden;
    }

    #sideBarWrapper {
        bottom: 0;
        left: 0;
    }

    #sideBar {
        width: calc(100vw);
        height: 70px;
        background: #fff;
        position: absolute;
        transform: translateY(-100%);
        top: 0;
        left: 0;
        z-index: 99;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding-top: 85px;
        gap: 10px;
        transition: .3s;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 40px;
        /* gap: 10px; */
        padding-top: 0;
    }

    #sideBar.open {
        width: calc(100vw);
        height: 70px;
        background: #fff;
        transform: translateY(0%);
        transition: .3s;
        top: unset;
        bottom: 0;
        left: 0;
        z-index: 99;
        padding-top: 0;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding-top: 85px;
        gap: 10px;
        transition: .3s;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 40px;
        /* gap: 10px; */
        padding-top: 0;
    }



    #contentSideWrapper {
        position: absolute;
        top: unset;
        bottom: 0;
        padding-top: 0;
        z-index: 9999;
        left: 0;
        transform: translateY(100%);
        height: min-content;
        /* width: 0; */
        width: 100vw;
        background: #fff;
        transition: .3s;
    }

    #contentSideWrapper.open {
        transform: translateY(0%);
        transition: .3s;
    }

    #contentSideWrapper .firstCol {
        width: 100%;

    }

    .content-wrapper {
        width: calc(100% - 40px);
    }
}