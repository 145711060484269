#followersWrapper {
    width: 733px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    padding: 14px 0;
    height: 100%;
    max-height: 50vh;
    overflow: auto;
    background: #fff;
}

#followersWrapper .header {
    border-left: 3px solid #FA6342;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: calc(50% - 200px - 72px - 20px);
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
}

#followersWrapper .header p {
    font-size: 14px;
    color: #535165;
    font-weight: 600;
}

#followersWrapper .header::before {
    content: "";
    position: absolute;
    bottom: -13px;
    left: 50%;
    width: calc(100% - 40px);
    transform: translateX(-50%);
    height: 2px;
    background: #EDE9E9;
}

#followersWrapper .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 15px;
    padding: 0 20px;
}

#followerWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 12px;
    background: #fff;
    width: 100%;
    border-radius: 5px;
    border-bottom: 2px dashed #EDE9E9;
    /* box-shadow: rgba(0, 0, 0, 0.15) -.5px 1.95px 2.6px; */
}

.profilePhotoWrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.followerHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.followerHeader .nameAndInformationsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.followerHeader .cityAgeZodiacWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.followerHeader .cityAgeZodiacWrapper p {
    font-size: 12px;
    color: #515365;
}

.followerHeader>.left {
    width: max-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.followerHeader>.right {
    width: max-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}


.followerHeader>p {
    text-transform: capitalize;
}

.followerName {
    text-transform: capitalize;
    color: #535165;
    font-size: 14px;
    font-weight: 600;
}

.followerBody {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


.followerBody .infoText {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 2px 10px;
    width: auto;
    white-space: nowrap;
    text-align: center;
    color: rgb(47, 85, 189);
    border-radius: 5px;
    background: #e5f3ff46;
}

.infoText>span {
    text-transform: capitalize;
    color: #666666;

}


.followerMessageButton {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    background: #ff937b;
    border: 1px solid #fa6442b2;
    color: #fff;
    padding: 3px 5px;
    border-radius: 100px;
    cursor: pointer;
    transition: .3s;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 7px;
    align-items: center;
    font-size: 13px;
}

/* .followerMessageButton:hover {
    background: #98cfff;
    transition: .3s;
    color: #fff;
} */
/* 
.followerMessageButton:hover>svg {
    fill: #fff;
} */

.followerMessageButton>svg {
    width: auto;
    height: 100%;
    fill: #fff;
}

/* .followerBody .infoText:nth-child(1) {
    background-color: rgb(36, 180, 84);
}

.followerBody .infoText:nth-child(4) {
    background-color: rgb(175, 8, 241);
}

.followerBody .infoText:nth-child(3) {
    background-color: rgb(241, 8, 183);
} */

.verticalLine {
    width: 2px;
    height: 100%;
    border-radius: 100px;
    background: rgb(47, 85, 189);
}

@media only screen and (max-width: 600px) {
    #followersWrapper {
        max-height: 54vh;
        width: 100%;
    }


    .followerMessageButton {
        font-size: 10px;
    }

    
#followersWrapper .header p {
    display: none;
}

}