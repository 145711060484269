:root {
    --inputBorderWidth: 2px;
}

.css-b62m3t-container {
    width: 100%;
    /* z-index: 3; */
}





.css-1fdsijx-ValueContainer {
    padding: 4.5px 8px !important;
}

.css-13cymwt-control {
    border-color: var(--inputBorderColor) !important;
    border-width: var(--inputBorderWidth) !important;
    border-radius: var(--inputBorderRadius) !important;
    box-sizing: border-box !important;
    background-color: transparent !important;
}

.css-13cymwt-control span {
    background-color: var(--inputTextColor) !important;
}

.css-13cymwt-control svg {
    fill: var(--inputLabelColor) !important;
}

.css-1jqq78o-placeholder {
    color: var(--inputLabelColor) !important
}

.css-1dimb5e-singleValue {
    color: var(--inputLabelColor) !important;
}

#react-select-3-listbox.css-1nmdiq5-menu {
    background-color: transparent !important;
}

#react-select-3-option-0.css-10wo9uf-option,
#react-select-3-option-1.css-10wo9uf-option {}

.css-1nmdiq5-menu {
    background-color: #fff !important;
    margin-top: 0 !important;
    border-radius: var(--inputBorderRadius) !important;
}

.css-1nmdiq5-menu div {
    border-radius: 5px !important;
    background-color: #fff;
}

.css-1nmdiq5-menu div>div {
    width: 97.5%;
    margin: 0 auto;
    color: #000;
    cursor: pointer;
}

.css-1nmdiq5-menu div>div:hover {
    background-color: #eee;

}

.css-1nmdiq5-menu div>div:active {
    background-color: #fff;
}

div[class*=indicatorContainer] {
    color: var(--inputTextColor) !important;
}

.css-1nmdiq5-menu div>div:not(div:last-child) {
    border-bottom: 1.5px var(--inputLabelColor) solid;
}



.css-1nmdiq5-menu div>div:last-child {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}



.css-t3ipsp-control:hover,
.css-t3ipsp-control {
    box-shadow: none !important;
    border-color: var(--inputBorderColor) !important;
    border-width: var(--inputBorderWidth) !important;
    border-radius: var(--inputBorderRadius) !important;
    box-sizing: border-box !important;
    background-color: transparent !important;
}


@media screen and (max-width: 391px) {
    .css-b62m3t-container>div {
        height: 60px !important;
    }
}