.paginationWrapper {
    width: min-content;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 7px;
}

.paginationWrapper>div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: min-content;
}

.paginationWrapper>.center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 7px
}

.paginationButton {
    width: 35px;
    height: 35px;
    border: 1px solid #D9D9D9;
    background: #fff;
    border-radius: 4px;
    cursor: pointer;
}

.paginationButton.selected {
    width: unset;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 7px;
    padding: 0 9px;
    position: relative;
}

.paginationButton:disabled {
    background: #eee;
}
.paginationButton:disabled path {
    fill: #c0c0c0;
}

.paginationButton.selected span {
    margin-left: 10px;
}

.paginationWrapper .dropdown {
    position: absolute;
    width: 100%;
    height: 0;
    display: none;
    bottom: -5px;
    left: 0;
    background: #fff;
    transform: translateY(100%);
    border: 1px solid #D9D9D9;
    /* display: flex; */
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.paginationWrapper .dropdown.open {
    height: auto;
    max-height: 245px;
    display: flex;
    overflow-y: auto;
}
.paginationWrapper .dropdown .option {
    width: 100%;
    height: 35px;
    min-height: 35px;
    background: #fff;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    border-bottom: 1px solid #D9D9D9;
}


@media screen and (max-width: 800px) {
    .paginationButton.allPages {
        display: none;
    }
    .paginationWrapper .center > svg {
        display: none;
    }
    .paginationButton.selected span {
        margin-left: 0;
    }
}