#youHaveNoProfileWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 22px;
    background: #fff;
    padding: 30px 64px;
    position: relative;
}

#youHaveNoProfileWrapper h1 {
    font-size: 30px;
    color: #515365;
    font-weight: 300;
    margin-bottom: 0;
}

#youHaveNoProfileWrapper>img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
}

.noProfileRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 140px;
}

.noProfileRow img {
    width: auto;
    height: 100%;
}

.noProfileRow p {
    max-width: 340px;
    font-size: 20px;
    color: #515365;
    font-weight: 300;
}

.noProfileRow p span {
    font-weight: bold;
}

.textAndIcon {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
}

.textAndIcon svg {
    background: #54667D;
    padding: 5px;
    width: 40px !important;
    height: 40px !important;
    min-width: 40px;
    border-radius: 100%;
}


@media screen and (max-width: 800px) {
    .noProfileRow {
        gap: 20px;
        height: 200px;
    }


    .noProfileRow p {
        font-size: 3vw;
    }
}

@media screen and (max-width: 630px) {
    #youHaveNoProfileWrapper {
        gap: 20px;
        padding: 20px 30px;
        height: auto;
    }

    .noProfileRow {
        gap: 15px;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    div.noProfileRow:nth-child(3) {
    flex-direction: column-reverse;
    }

    .noProfileRow img {
        width: 100%;
        height: auto;
    }

    .noProfileRow p {
        font-size: 20px;
    }
}