.postsScrollView {
    overflow-y: auto;
    /* max-height: 60vh; */
    scroll-behavior: smooth;
    padding: 1px;
    overflow-x: hidden;
}

#empty-profile-wrapper {
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.empty-profile-title {
    color: #2873b8;
}

.empty-profile-h2 {
    color: #548cc0;
    font-size: 20px;
    text-align: center;
}

.emptyBoxesPng {
    width: auto;
    height: 50%;
}

/* .fadeEffect {
    position: absolute;
    width: 100%;
    height: 100px;
    pointer-events: none !important;
    top: 0;
    left: 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(250, 250, 250, 0.456) 62%, rgba(0, 212, 255, 0) 100%);
} */

/* #ScrollViewAndSendPost {
    position: relative;
} */

.post-sorting-button.counts {}

.post-sorting-button {
    /* border: none;
    background: #2873b8;
    color: #fff;
    padding: 5px 15px;
    border-radius: 100px;*/
    min-width: 150px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
}

.texareaAndEmojiWrapper {
    height: 100%;
    position: relative;
    display: flex;
    width: calc(100% - 40px);
}

.emojiButton {
    /* position: absolute; */
    top: 50%;
    right: 25px;
    width: 40px;
    height: 40px;
    /* transform: translate(50%, -50%); */
    fill: #2873b8;
    cursor: pointer;
    transition: .2s;
    border-radius: 100px;
    padding: 0;
    background: transparent;
    box-shadow: none !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.emojiButton>svg {
    width: 100%;
    height: 100%;
}

.emojiButton:hover {
    background: #f6fbff;
    transition: .2s;
}


.textAreaAndSendButtonWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 100%;
}

.sendPostWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: auto;
    gap: 12px;
    position: relative;
}

.sendPostWrapper textarea {
    resize: none;
    width: 100%;
    height: inherit;
    font-size: 16px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border: none;
    outline-style: none;
    background: transparent;
    border-radius: 15px;
    padding: 7px 6px 7px 12px;
    /* box-shadow: rgba(0, 0, 0, 0.2) -0px 1.95px 2.6px; */
}

.sendPostWrapper textarea::-webkit-scrollbar {
    display: none;
}

.sendPostWrapper .texareaAndEmojiWrapper p {
    position: absolute;
    top: 11px;
    left: 9px;
    color: #515365;
    font-size: 14px;
    pointer-events: none;
}

.sendPostWrapper .texareaAndEmojiWrapper p.none {
    display: none;
}

#sendPostButton {
    width: 88px;
    height: 30px;
    background: #FA6342;
    border: none;
    border-radius: 100px;
    color: #fff;
    cursor: pointer;
    margin-top: auto;
}

#sendPostButton svg {
    width: auto;
    height: 100%;
}

.sortingButtonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: min-content;
    gap: 11px;
    margin: 10px 0;
}

.sortingButtonsWrapper .sortingButton {
    background: #fff;
    color: #515365;
    border: 1px solid #515365;
    width: min-content;
    white-space: nowrap;
    padding: 10px 11px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}

.sortingButtonsWrapper .sortingButton.active {
    background: #FA6342;
    color: #fff;
    border: 1px solid #FA6342;
}



/* EMOJI PICKER OVER-RIDE */
.EmojiPickerReact.epr-main {
    position: absolute !important;
    bottom: 0;
    right: 0;
    transform: translate(0%, 100%);
    z-index: 999;
    box-shadow: rgba(100, 100, 111, 0.5) 0px 20px 29px 2px;
}

@media only screen and (max-width: 600px) {
    .EmojiPickerReact.epr-main {
        transform: translate(50%, 100%);
        right: 50%;
    }
}

.sendPostWrapper button {
    height: 40px;
    width: 40px;
    height: inherit;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border: none;
    background: #e3f2ff;
    cursor: pointer;
    border-radius: 100px;
    box-shadow: rgba(0, 0, 0, 0.2) -0px 1.95px 2.6px;
    outline: none;
    /* animation: none; */
    transition: all .2s ease-in-out;
}

.sendPostWrapper button:active {
    transition: all .2s ease-in-out;
    background: #2873b8;
}

.sendPostWrapper button:active>svg {
    fill: #e3f2ff;
}

.sendPostWrapper button svg {
    fill: var(--inputBorderColor);
    width: 37px;
    height: inherit;
    padding: 6px;
}


.profilePhoto {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    overflow: hidden;
}

#sendPost-AboutContent {
    background: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px 14px 16px 16px;
    height: max-content;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    height: auto;
    border: 1px solid #EDE9E9;
}

#sendPost-AboutContent h1 {
    font-size: 15px;
    color: #515365;
    font-weight: 300;
}

.divider {
    width: 100%;
    background: #EDE9E9;
    height: 1px;
}

@media screen and (max-width: 800px) {
    .sortingButtonsWrapper .sortingButton {
        font-size: 10px;
    }

    .textAreaAndSendButtonWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        width: 100%;
    }

    #sendPostButton {
        width: 100%;
    }

    .sendPostWrapper {
        flex-direction: column;
        gap: 12px;
    }

    .texareaAndEmojiWrapper {
        width: 100%;
    }
}