:root {
  --inputBackground: transparent;
  --inputBorderColor: #fff;
  --inputLabelColor: #fff;
  /* b4dafe */
  --inputTextColor: #fff;
  --inputBorderWidth: 2px;
  --inputBorderRadius: 100px;
  --homeBackgroundColor: #EDF2F6;
  --buttonColor: #1d90f5;
}

*:not(input), *:not(u), *:not(strong) {
  user-select: auto;
  -webkit-user-drag: auto;
  -webkit-user-select: auto;
}

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  pointer-events: auto;
}

a,
p,
h1,
h2,
h3,
h4 {
  font-family: inter, sans-serif;
}


#Body {
}


.toDissapear {
  width: 40px;
  height: 40px;
  background: #54667D;
  border-radius: 100%;
  border: none;
  cursor: not-allowed;
  animation: toDissapear .2s linear forwards;
}

@keyframes toDissapear {
  0% {
    transform: scale(1)
  }
  99% {
    transform: scale(0)
  }
  100% {
    display: none;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0px 10px var(--inputLabelColor);
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #FA6342;
  border-radius: 5px;
  transition: .3s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--inputBorderColor);
  transition: .3s;
}

:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  --background: #FA6342
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


html,
body {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
}

body {
  position: relative;
  width: 100%;
  height: 100vh;
}

#root {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}


footer {
  background: #E6EBEF;
  height: 60px;
  min-height: 60px;
  width: 100%;
  position: relative;
  z-index: 98;
}

footer>p,
footer>a {
  position: absolute;
  transform: translate(-50%, -50%);
  color: #515365;
  font-size: 13px;
  font-weight: 300;
}

footer>p {
  top: 50%;
  left: 50%;
}

footer>a {
  top: 50%;
  left: 70%;
  font-weight: 500;
  text-decoration: underline;
}

@media screen and (max-width: 800px) {
  footer {
    bottom: 70px;
  }

  footer>p {
    top: 50%;
    transform: translate(0%, -50%);
    left: 20px;
  }

  footer>a {
    top: 50%;
    left: unset;
    transform: translate(0%, -50%);
    right: 20px;
    font-weight: 500;
    text-decoration: underline;
  }
}

#spinnerWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 99;
  filter: blur(5px);
}

#spinnerWrapper.pending {
  backdrop-filter: blur(0);
  cursor: wait;
  user-select: none;
}

#spinnerWrapper + span {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}


#AllWrapper:not(div:has(#registerPage), div:has(#loginStatsAndContainerWrapper)){
  padding: 66px 0 0 0;
}

@media screen and (max-width: 800px) {

  #AllWrapper:not(div:has(#registerPage), div:has(#loginStatsAndContainerWrapper)) {
    padding: 112px 0 70px 0;
  }
}