#CropWrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

#CropWrapper.close {
    display: none;
}

.dropzone {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #EDE9E9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

#select-image-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

/* #select-image-wrapper.false {
    display: none;
    pointer-events: none;
} 

.dropzone:has(#select-image-wrapper.false) {
    background: transparent;
    pointer-events: none;
}  */

#image-file-1 {
    display: none;
}

#image-file-1-label {
    background: transparent;
    border: 1.5px solid #2873b8;
    border-radius: 5px;
    padding: 3px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    cursor: pointer;
    transition: .2s;
    margin-top: 25px;
}

#image-file-1-label>span {
    color: #2873b8;
}

#image-file-1-label>svg {
    height: 100%;
    width: auto;
    fill: #2873b8;
}


#image-file-1-label:hover {
    background: #2873b8;
    transition: .2s;
}

#image-file-1-label:hover>span {
    color: #e3f2ff;
}

#image-file-1-label:hover>svg {
    height: 100%;
    width: auto;
    fill: #e3f2ff;
}

#image-upload-text {
    color: #2873b8;
    margin-top: -25px;
}

#select-image-svg-wrapper {
    position: relative;
    width: 50%;
    height: 50%;
}

#imagesSvg {
    width: 15%;
    height: 15%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    fill: #2873b8;
}

#cropFrameSvg {
    width: 80%;
    height: 80%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    fill: #2873b8;
}

.crop-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.dropzone.none {
    border: 0px dashed transparent;
}


.cropCloseButton {
    position: absolute;
    top: 15px;
    right: 15px;
    transform: translate(0%, 0%);
    border: none;
    background: transparent;
    padding: 5px;
    width: 25px;
    aspect-ratio: 1;
    cursor: pointer;
    transition: .1s;
    z-index: 999;
}
.cropCloseButton > svg {
    width: 100%;
    height: 100%;
   transition: .2s;
}
.cropCloseButton:hover > svg path {
   fill: #FA6342;
   transition: .2s;
}

.ReactCrop.ReactCrop--fixed-aspect {
    height: 100%;
}

.image-wrapper {
    height: 100%;
}

.ReactCrop__child-wrapper {
    /* width: 100%; 
    height: 100%; */
    height: 100%;
}

#previewCanvas {
    /* width: 100%;
    height: 100%; */
}

#selectedCrop {
    background: transparent;
    border: 1.5px solid #2873b8;
    border-radius: 5px;
    padding: 3px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    cursor: pointer;
    transition: .2s;
    margin-top: 15px;
    gap: 5px;
}

#selectedCrop:hover {
    background: #2873b8;
    transition: .2s;
}

#selectedCrop svg {
    fill: #2873b8;
    width: 27px;
}

#selectedCrop svg path {
    width: 100%;
}

#selectedCrop span {
    color: #2873b8;
    text-transform: capitalize;
}

#selectedCrop:hover svg {
    fill: #e3f2ff;
}

#selectedCrop:hover span {
    color: #e3f2ff;
}


.cropFooter {
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}