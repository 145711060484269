#add-profile-modal {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(47, 48, 49, .6);
    top: 0;
    left: 0;
    z-index: 99;
    display: none;
}

#add-profile-modal.open {
    display: flex;
}

#add-profile-modal .container {
    width: 100%;
    height: 100%;
    max-width: 747px;
    max-height: 457px;
    position: absolute;
    background: #fff;
    border-bottom: 5px solid #FA6342;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: rgba(0, 0, 0, 0.15) -1.95px 1.95px 2.6px;
    padding: 30px 15px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.horoscopeSideWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 18px;
}

.horoscopeSideWrapper .labelAndInput {
    width: 100%;
}

.inputAndZodiac>div {
    width: 100%;
}

.inputAndZodiac {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
}

.inputAndZodiac .border-bottom-date-wrapper {
    min-width: unset;
}

.horoscopeSideWrapper .zodiacSign {
    width: 35px;
    aspect-ratio: 1;
    background: #23D2E2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    padding: 5px;
}

.horoscopeSideWrapper .zodiacSign.feMale {
    background: #FC8DCA;
}

.horoscopeSideWrapper .zodiacSign svg {
    height: 100%;
}

.horoscopeSideWrapper .zodiacSign path {
    fill: #fff;
}

.labelAndDateAndHoroscopeWrapper {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.dateAndHoroscopeWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.saveAndCloseButtonsWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-top: 42px;
}

#add-profile-modal .left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 190px;
    border-right: 2px dashed #C4D6ED;
    /* background: red; */
}

#profileSelectMethodWrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 23px
}

.formWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 14px;
}

.profileSelectMethodButton {
    width: 170px;
    height: 88px;
    border: 1px dashed #515365;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 14px 15px;
    cursor: pointer;
}

.profileSelectMethodButton.selected {
    border: 1px dashed #FA6342;
}

.profileSelectMethodButton.selected p {
    color: #FA6342;
}

.profileSelectMethodButton img {
    width: auto;
    height: 32px;
}

#add-profile-modal .right {
    padding-left: 26px;
    padding-right: 32px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

#add-profile-modal .right .textWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

}

#add-profile-modal .right .textWrapper h1 {
    font-size: 20px;
    font-weight: 500;
}

#add-profile-modal .right .textWrapper p {
    font-size: 13px;
    font-weight: 300;
}

#add-profile-modal .header {
    width: 100%;
    background-color: black;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.close-button {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(25%, -25%);
    border: none;
    background: rgb(255, 115, 115);
    padding: 5px;
    border-radius: 100px;
    width: 30px;
    aspect-ratio: 1;
    color: #fff;
    cursor: pointer;
    transition: .1s;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) -3px 3px 5px;
}

.close-button:hover {
    transition: .1s;
    transform: translate(25%, -25%) scale(1.1);
    border: none;
    background: rgb(255, 166, 166);
}


@media only screen and (max-width: 600px) {}

@media only screen and (max-width: 747px) {


    #add-profile-modal .container {
        flex-direction: column;
        max-height: 620px;
        max-width: 98%;
    }

    #add-profile-modal .left {
        width: 100%;
        max-width: 100%;
        border-right: none;
    }

    #add-profile-modal .right {
        padding: 0;
    }

    .savedProfilesH1 {
        /* text-align: start; */
    }

    #profileSelectMethodWrapper {
        margin-bottom: 18px;
    }

    .horoscopeSideWrapper {
        flex-direction: column;
    }

    .savedProfilesAndTitle {
        width: 100%;
    }

    .savedProfileWrapper {
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        overflow-x: visible;
    }

    .savedProfileWrapper::-webkit-scrollbar {
        width: 0px !important;
    }

    .profilesWrapper {
        min-width: 150px;
        border-right: 2px dashed #C4D6ED;
    }
}