.searchInput {
    
    width: 100%;
    max-width: 400px;
    height: 35px;
    background: #EDF2F6;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 0 8px 40px;
    position: relative;

}

.searchInput>svg {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 20px;
    height: 70%
}

.searchInput>input {
    border: none;
    background: transparent;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
}