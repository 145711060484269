#personInfoModalWrapper {
    width: 493px;
    height: 100%;
    position: absolute;
    transform: translateX(calc(100% - 40px));
    top: 0;
    right: 0;
    background: #fff;
    z-index: 99;
    transition: .3s;
}

#personInfoModalWrapper.open {
    transition: .3s;
    transform: translateX(0%);
}

#personInfoModalWrapper .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
}

#personInfoModalWrapper .header .toggle-button {
    background: transparent;
    padding: 0;
    border: none;
    width: 18px;
    height: 20px;
}

#personInfoModalWrapper .header .toggle-button svg {
    width: 100%;
    height: 100%;
    cursor: pointer;
}



#personInfoModalWrapper .body .sec-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 262px;
    border-bottom: 1px solid #EAEAEA;
    margin: 0 45px;
}


#personInfoModalWrapper .body .sec-1 h1 {
    color: #FA6342;
    font-size: 18px;
    font-weight: medium;
    text-transform: capitalize;
    margin-top: 10px;
}

#personInfoModalWrapper .body .sec-1 p {
    color: #515365;
    font-size: 13px;
    font-weight: 300;
    text-transform: capitalize;
}

#personInfoModalWrapper .body .sec-2 {
    margin: 33px 45px 0 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px
}
#personInfoModalWrapper .body .sec-2 .row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 22px;
}

#personInfoModalWrapper .body .sec-2 .row:nth-child(1) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 11px;
}

#personInfoModalWrapper .body .sec-2 label {
    color: #515365;
    font-weight: 500;
    font-size: 14px;
}
#personInfoModalWrapper .body .sec-2 p {
    color: #959AB5;
    font-weight: 400;
    font-size: 14px;
}

.infoTableWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 14px;
}

.infoTableWrapper .keys {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.infoTableWrapper .values {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

@media screen and (max-width: 800px) {
    #personInfoModalWrapper {
        transform: translateX(100%);
        width: 100%;
    }
    #personInfoModalWrapper .header .toggle-button.inOpen {
        transform: translate(0, 0);
        width: 30px;
        height: 30px;
    }
    #personInfoModalWrapper .header .toggle-button {
        transform: translate(-55px, 7px);
        width: 30px;
        height: 30px;
    }

}