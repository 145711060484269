#messagesSearchInputWrapper {
    width: 100%;
    height: 35px;
    background: #EDF2F6;
    border-radius: 5px;
    position: relative;
}

.messagesSearchInput {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline-style: none;
    padding: 10px 18px;
}

#messagesSearchInputWrapper svg {
    width: 14px;
    height: 14px;
    fill: #515365;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%)
}