.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    height: 45px !important;
    padding: 0 14px !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    top: -13% !important;
}


fieldset {
    border-color: var(--inputBorderColor) !important;
    border-width: var(--inputBorderWidth) !important;
    border-radius: var(--inputBorderRadius) !important;
}

.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
}

.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root label {
    color: var(--inputLabelColor) !important;
    z-index: 0;
}

.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root svg {
    fill: var(--inputLabelColor) !important;
}

.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root input {
    color: var(--inputTextColor) !important;
}

.MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-outlined.MuiInputAdornment-sizeMedium.css-1laqsz7-MuiInputAdornment-root {
    display: flex !important;
}

/* for live */
.MuiFormControl-root.MuiTextField-root.css-i44wyl {
    width: 100%;
}

.MuiFormControl-root.MuiTextField-root.css-i44wyl label {
    color: var(--inputLabelColor) !important;
}

.MuiFormControl-root.MuiTextField-root.css-i44wyl svg {
    fill: var(--inputLabelColor) !important;
}

.MuiFormControl-root.MuiTextField-root.css-i44wyl input {
    color: var(--inputTextColor) !important;
}