.searchedUserRowWrapper {
    width: 100%;
    height: 100%;
    max-height: 130px;
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    border: 1px solid #EDE9E9;
    box-sizing: border-box;
    border-radius: 5px;
}

.searchedUserRowWrapper .isOnlineDot {
    display: block;
    width: 15px;
    height: 15px;
    background: #7FBA00;
    border-radius: 100%;
}

.searchedUserRowWrapper .nameAndisOnlineWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.searchedUserRowWrapper .nameAndisOnlineWrapper p {
    font-size: 15px;
    text-transform: capitalize;
}

.searchedUserRowWrapper .left {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.searchedUserRowWrapper .squareProfileImage {
    width: 115px;
    height: auto;
    aspect-ratio: 115 / 90;
    border-radius: 5px;
    overflow: hidden;
}

.searchedUserRowWrapper .squareProfileImage img {
    width: 100%;
    height: 100%;
    background: #000;
}


.searchedUserRowWrapper .nameAndStatWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.searchedUserRowWrapper .iconAndStatRowsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.searchedUserRowWrapper .iconAndStatRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
}



.searchedUserRowWrapper .right {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}


.searchedUserRowWrapper .right .button {
    width: 100%;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    background: #FA6342;
    border: 1.5px solid #FA6342;
    border-radius: 15px;
    box-shadow: 4px 7px 12px rgba(250, 99, 66, 0.2);
}

.searchedUserRowWrapper .right .button.outlined {
    width: 100%;
    font-size: 13px;
    font-weight: 600;
    color: #FA6342;
    background: transparent;
    border: 1.5px solid #FA6342;
    border-radius: 15px;
}

.iconAndStatRow svg {
    width: auto;
    height: 15px;
}

.iconAndStatRow .zodiakSignWrapper {
    background: transparent;
}

.iconAndStatRow .zodiakSignWrapper svg {
    width: 25px;
    height: 25px
}

.iconAndStatRow .zodiakSignWrapper svg path {
    fill: #B3B1C5;
}

.iconAndStatRow p {
    font-size: 13px;
    color: #B3B1C5;
}

@media screen and (max-width: 550px) {
    .searchedUserRowWrapper {
        max-height: unset;
        height: auto;
        align-items: flex-end;
    }

    .searchedUserRowWrapper .left {
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        height: auto;
    }

    .searchedUserRowWrapper .squareProfileImage {
        width: 90px;
    }

    .searchedUserRowWrapper .nameAndStatWrapper {
        margin-top: 9px;
    }

    .searchedUserRowWrapper .right {
        margin-bottom: 10px;
        height: max-content;
    }

    .searchedUserRowWrapper .right .button,
    .searchedUserRowWrapper .right .button.outlined {
        font-size: 11px;
        padding: 0;
        width: 100px;
        height: 20px;
    }
}