.home-header {
    width: 100%;
    height: 66px;
    background: #34465E;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 7px;
    padding: 0 50px;
}

#brandName {
    font-family: handWrite;
    color: #fff;
    user-select: none;
    cursor: pointer;
}

#brandName>span {
    color: #50C16D;
}

.header-right-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

#headerDropDown {
    width: 170px;
    height: 0;
    background: #fff;
    position: absolute;
    bottom: -12px;
    left: 0;
    transform: translate(-60%, 103%);
    transition: .3s;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.3) -3px 3px 12px;
    border-radius: 5px;
    z-index: 5;
    gap: 15px;
}

.triangleSvg {
    position: absolute;
    top: 2px;
    right: 41px;
    transform: translateY(-100%)
}

#headerDropDown.active .header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    width: 100%;
    border-bottom: 1px solid #EDE9E9;
    padding-bottom: 8px;
}

#headerDropDown .photoAndName {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 100%;
}

#headerDropDown .photoAndName h1 {
    font-size: 16px;
    color: #535165;
    font-weight: 600;
    text-transform: capitalize;

}


.zodiakSignWrapper {
    background: #5D6B7E;
    border-radius: 100%;
    width: 35px;
    min-width: 35px;
    height: 35px;
    min-height: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.zodiakSignWrapper.male * {
    fill: #AAECFC;

}

.zodiakSignWrapper.feMale * {
    fill: #FC8DCA;

}

.zodiakSignWrapper svg {
    width: 23px;
    height: 23px;
}

.headerDropDownProfileSpansWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.headerDropDownProfileSpan:nth-child(1) {
    font-size: 10px;
    color: rgb(144, 202, 249);
}

.headerDropDownProfileSpan:nth-child(2) {
    font-size: 10px;
    color: rgb(206, 147, 216);
}

#headerDropDown * {
    display: none;
}

#headerDropDown.active * {
    display: flex;
}

#headerDropDown.active {
    height: auto;
    transition: .3s;
    padding: 8px 10px;
}

.currentProfileCard {
    width: 100%;
    background: #e3f2ff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.2) -3px 3px 10px;
    margin-bottom: 15px;
}

.currentProfileCard>.row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    width: 90%;
    padding: 5px;
    border-bottom: 1px solid #556d85;
}

.currentProfileCard>.row:nth-child(2) {
    border-bottom: none;
}

.currentProfileCard>.row>h1 {
    font-size: 24px;
    color: #535353;
}

.currentProfileCard>.row>a {
    font-size: 10px !important;
}



.currentProfileCard>.profilePhotoWrapper {
    width: 25px;
    height: 25px;
}

.header-setting {
    width: 100%;
    /* height: 70px; */
    font-family: unset;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
    transition: .3s;
}

.iconAndLink {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 16px;
}

.iconAndLink p {
    color: #515365;
    font-size: 14px;
    font-weight: 300;
}

.header-setting p {
    color: #515365;
    font-size: 14px;
    font-weight: 300;
}

.header-setting svg {
    height: 15px;
    width: auto;
}


.header-setting:hover svg {
    animation: settingRotate 1s linear infinite;
    transition: .3s linear all;
}

.header-setting.log-out {
    margin-bottom: 7px;
}

.header-setting.log-out:hover svg {
    animation: none;
}

.header-setting.log-out:hover p {
    color: red;
    transition: .15s;
}

.header-setting.profile {
    display: flex;
    flex-direction: column;
    gap: 0;
}

.header-setting.profile svg {
    animation: none;
}

.header-setting.profile .first-row {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.profileNamePWrapper {
    max-width: 120px;
    overflow: hidden;
    width: 11rem;
    white-space: nowrap;
}

.profileNameP {
    user-select: none;
}

.profileNameP.fit {
    /* min-width: 100%; */
}

.profileNameP:not(p[class *="fit"]),
#selectedProfileLabel:not(p.fit) {
    display: inline-block;
    position: relative;
    animation: 3s linear 0s infinite alternate longTextSlideAnimation;
}

@keyframes longTextSlideAnimation {

    0%,
    25% {
        transform: translateX(0%);
        left: 0%;
    }

    75%,
    100% {
        transform: translateX(-100%);
        left: 100%;
    }
}

.profle-dropdown>svg {
    position: absolute;
    top: 2px;
    transform: translateY(-100%);
    right: 0;
    z-index: 9999999999;
    width: 20px;
    height: 17px;
}

.profle-dropdown>svg>path {
    fill: #fff;
}

.profle-dropdown {
    position: absolute;
    background: #fff;
    z-index: 999;
    bottom: -3px;
    left: 0;
    transform: translateY(100%);
    width: 100%;
    height: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    display: none;
}


.paginationWrapper.up .dropdown.open {
    transform: translateY(-100%);
    bottom: unset;
    top: -3px;
}

.profle-dropdown.open {
    height: auto;
    display: block;
}

.header-center-wrapper {
    height: 100%;
}

.selectedProfileNameWrapper {
    max-width: 120px;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#selectedProfileWrapper {
    cursor: pointer;
    width: 100%;
    height: 100%;
}

#dosentHaveProfileWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 11px;
    width: 100%;
    height: 100%;
    cursor: default;
}

#dosentHaveProfileWrapper p {
    color: #fff;
    font-size: 16px
}

#profileSelectWrapper {
    width: 254px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}

/* #profileSelectWrapper içerisindeki OutsideClickHandler için */
#profileSelectWrapper>div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}

/* -------------------------------------------------------------- */
#selectedProfileWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 1px;
}

#selectedProfileWrapper .down>svg {
    transform: rotate(180deg);
    transition: .3s;
}

#selectedProfileWrapper .up>svg {
    transform: rotate(0);
    transition: .3s;
}


#selectedProfileLabel {
    font-size: 20px;
    color: #fff;
    user-select: none;
    overflow-x: hidden;
}

.empty-profile-text {
    font-size: 13px;
    font-weight: 400;
    color: #e4b10b !important;
}

.addProfileButton {
    background: transparent;
    border: none;
    margin-left: 45px;
    height: 30px;
    cursor: pointer;
}

.addProfileButton svg {
    height: 100%;
    background: #f1f7fd;
    transition: .3s;
}

.addProfileButton:hover svg {
    background: #2873b8;
    fill: aliceblue;
    transition: .3s;
}

.headerDropDownProfilesButton {
    background: #eef7ff;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    width: 100%;
    border: none;
    padding: 10px 5px;
    cursor: pointer;
    transition: .3s;
    color: #0e80eb;
    text-transform: capitalize;
    font-size: 15px;
}

.headerDropDownProfilesButton:hover {
    background: #e3f2ff;
    transition: .3s;
}


.see-profile-link {
    color: #535165;
    font-size: 13px;
    font-weight: 500;
    font-family: sans-serif;
    letter-spacing: .5px;
}

.see-profile-link:hover {
    color: #5f8fbd !important;
    transition: .15s;
}

.see-profile-link {
    transition: .15s;
}

@keyframes settingRotate {
    0% {
        transform: rotateZ(0deg);
    }

    50% {
        transform: rotateZ(180deg);
    }

    100% {
        transform: rotateZ(360deg);
    }
}


#profilePhotoWrapper {
    overflow: hidden;
    border-radius: 50%;
    width: 100%;
    height: 100%;
}



#profilePageIcon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0.2);
    background: transparent;
    position: relative;
    cursor: pointer;
}

.header-user-name {
    /* font-family: roboto; */
    font-size: 14px;
    color: #fff;
    margin-right: 5px;
}

.isOnline {
    display: block;
    position: absolute;
    transform: translate(35%, 35%);
    bottom: 0;
    right: 0;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #7FBA00;
}

.isOfline {
    display: block;
    position: absolute;
    transform: translate(35%, 35%);
    bottom: 0;
    right: 0;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #BEBEBE;
}

.isOfline:before {
    content: '';
    width: 6px;
    height: 2px;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}




@media screen and (max-width: 800px) {
    #profilePageIcon {
        width: 50px;
        height: 50px;
    }

    #smalMessagesButton svg {
        width: 35px;
        aspect-ratio: 20px / 23px;
    }

    #smalMessagesButton {
        width: 50px;
        height: 50px;
    }

    .home-header {
        padding: 10px 20px;
        flex-wrap: wrap;
        justify-content: space-between;
        /* gap: 15px; */
        height: auto;
    }

    .header-center-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    /* .home-header {
        width: 100%;
        height: 66px;
        background: #34465E;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        top: 0;
        z-index: 99;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 7px;
        padding: 0 50px;
    } */

    #profileSelectWrapper {
        /* width: 154px; */
    }
}