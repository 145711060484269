#securityPage {
    width: 100%;
    height: auto;
    min-height: 100%;
    background: #EDF2F6;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#securityPage .header {
    width: 100%;
    height: 150px;
    background: #495B72;
    position: relative;
}


#securityPage .header h1 {
    font-size: 40;
    font-weight: 400;
    color: #fff;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}


#securityPage .header img {
    width: auto;
    height: 80px;
    max-height: 60%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
}


#securityPage .body {
    width: 100%;
    height: calc(100% - 150px);
    max-width: 1000px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    padding: 40px 10px 20px 70px;
}

#securityPage .body .titlesWrapper:before {
    content: "";
    width: 3px;
    height: 24px;
    background: #FA6342;
    position: absolute;
    top: 17px;
    left: 0;
    border-radius: 5px;
}

#securityPage .body .titlesWrapper:after {
    content: "";
    width: 100%;
    height: 1px;
    background: #EDE9E9;
    position: absolute;
    top: calc(17px + 24px + 9px);
    left: 0;
    border-radius: 5px;
}


#securityPage .body .titlesWrapper {
    background: #fff;
    padding: 17px 15px;
    width: 253px;
    min-width: 253px;
    height: auto;
    position: relative;
    border-radius: 5px;
    border: 1px solid #EDE9E9;
}

#securityPage .titlesWrapper .firstRow h2 {
    font-size: 15px;
    font-weight: 400;
    color: #515365;
}

#securityPage .titlesWrapper .titlesList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    padding: 18px 0;
}

#securityPage .titlesWrapper .titlesList>div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

#securityPage .titlesWrapper .titlesList p {
    font-size: 13px;
    color: #515365;
    cursor: pointer;
}

#securityPage .titlesWrapper .titlesList .selected p {
    color: #FA6342;
}

#securityPage .titlesWrapper .titlesList .selected path {
    fill: #FA6342;
}


#securityPage .body .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: #fff;
    padding: 32px;
    gap: 27px;
    border-radius: 5px;
    border: 1px solid #EDE9E9;
}


#securityPage .body .content h1 {
    color: #515365;
    font-size: 24px;
    font-weight: 500;
    border-bottom: 1px solid #EDE9E9;
    padding-bottom: 20px;
    width: 100%;
}


#securityPage .body .content p {
    color: #959AB5;
    font-size: 14px;
    font-weight: 400;

}


@media screen and (max-width: 800px) {

    #securityPage .header {
        width: 100%;
        height: 120px;
    }


    #securityPage .body {
        width: 100%;
        height: calc(100% - 120px);
    }

    #securityPage .header img {
        width: auto;
        height: auto;
        max-width: 100%;
    }
}


@media screen and (max-width: 600px) {

    #securityPage .header {
        width: 100%;
        height: 85px;
    }


    #securityPage .body {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 20px 20px;
    }

    #securityPage .body .titlesWrapper {
        width: 100%;
        max-width: 100%;
    }

    #securityPage .header h1 {
        width: max-content;
        font-size: 21px;
    }

}