#MembershipPlansWrapper {
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#MembershipPlansWrapper .header {
    width: 100%;
    height: 100%;
    max-height: 130px;
    background: #495B72;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

#MembershipPlansWrapper .header h1 {
    font-size: 40px;
    color: #fff;
    font-weight: 400;
}

#MembershipPlansWrapper .header img {
    width: auto;
    height: 60px;
}


#MembershipPlansWrapper .body {
    width: 100%;
    height: calc(100% - 120px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    padding-top: 15px;
}

#MembershipPlansWrapper .body>h1 {
    font-size: 40px;
    text-align: center;
}

#MembershipPlansWrapper .body>h1 span {
    color: #FA6342;
}

#MembershipPlansWrapper .body .plansRow {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

#MembershipPlansWrapper .body .navRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

#MembershipPlansWrapper .body .navRow .navButton {
    border: 1px solid #FA6342;
    font-size: 20px;
    font-weight: 400;
    background: transparent;
    width: 200px;
    height: 50px;
    color: #FA6342;
    cursor: pointer;
    transition: .3s;
}

#MembershipPlansWrapper .body .navRow .navButton.selected {
    background: #FA6342;
    color: #fff;
    transition: .3s;
}

#MembershipPlansWrapper .body .navRow .navButton:first-child {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
}

#MembershipPlansWrapper .body .navRow .navButton:last-child {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
}

#MembershipPlansWrapper .body .plansRow {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

#MembershipPlansWrapper .body .plansRow .planBox {
    width: 350px;
    height: 450px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0px 26px 56px 26px;
    box-shadow: rgba(0, 0, 0, 0.25) 0 10px 20px;
    cursor: pointer;
}

.nickTitlePriceWrapper {
    width: inherit;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#MembershipPlansWrapper .planBox .nick {
    width: inherit;
    font-size: 13px;
    text-align: center;
    color: #fff;
}

#MembershipPlansWrapper .planBox .title {
    font-size: 40px;
    text-align: center;
    color: #000;
    font-weight: 500;
}

#MembershipPlansWrapper .planBox .price {
    font-size: 45px;
    text-align: center;
    font-weight: 500;
    color: #000;
    position: relative;
    width: min-content
}

#MembershipPlansWrapper .planBox .price.month::before {
    content: "aylık";
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%);
    font-size: 15px;
    color: #FA6342;
    display: block;
    text-transform: capitalize;
    font-style: italic;
}

#MembershipPlansWrapper .planBox .price.year::before {
    content: "yıllık";
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%);
    font-size: 15px;
    color: #FA6342;
    display: block;
    text-transform: capitalize;
    font-style: italic;
}

#MembershipPlansWrapper .planBox .description {
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    color: #515365;
    margin-bottom: 20px;
}

#MembershipPlansWrapper .planBox .addsOddsList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

#MembershipPlansWrapper .planBox .addsOddsList .iconAndText {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

#MembershipPlansWrapper .planBox .addsOddsList p {
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    color: #515365;
}

#MembershipPlansWrapper .optionWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 230px;
    height: 30px;
    position: relative;
    border-radius: 100px;
    border: 1px solid #FA6342;
}

#MembershipPlansWrapper .optionWrapper button {
    background: transparent;
    font-size: 13px;
    border: none;
    width: 50%;
    color: #FA6342;
    z-index: 1;
    cursor: pointer;
    transition: .2s;
    user-select: none;
}

#MembershipPlansWrapper .optionWrapper span {
    width: calc(50% - 4px);
    height: calc(100% - 4px);
    border-radius: inherit;
    background: #FA6342;
    display: block;
    position: absolute;
    top: 50%;
    left: 1px;
    z-index: 0;
    transition: .2s;
    transform: translate(-50%, -50%);
}


#MembershipPlansWrapper .optionWrapper span.left {
    left: 25%;
}

#MembershipPlansWrapper .optionWrapper span.right {
    left: 75%;
}

#MembershipPlansWrapper .optionWrapper button.selected {
    color: #fff;
}

@media screen and (max-width: 500px) {
    #MembershipPlansWrapper .body>h1 {
        font-size: 22px;
    }
    #MembershipPlansWrapper .body .navRow .navButton {
        width: 125px;
        height: 30px;
        font-size: 14px;
        
    }
    #MembershipPlansWrapper .body .plansRow {
        overflow-y: auto;
        height: 100%;
        
    }

    #MembershipPlansWrapper .header h1 {
        font-size: 30px;
        
    }
  
}

