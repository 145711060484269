.loginMissingInfoModalWrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99;
    background: #00000046;
}


.loginMissingInfoModal {
    width: 100%;
    height: 100%;
    max-width: 500px;
    max-height: 292px;
    background: #fff;
    border-bottom: 5px solid #FA6342;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 23px 35px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    gap: 35px;
}

.loginMissingInfoModal .header {
    width: 100%;
    height: 55px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 12px;
}

.loginMissingInfoModal .header .titleAndPWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}

.loginMissingInfoModal .header .titleAndPWrapper h1 {
    font-size: 20px;
    color: #515365;
    font-weight: 400
}

.loginMissingInfoModal .header .titleAndPWrapper p {
    font-size: 13px;
    color: #515365;
    font-weight: 300;
    line-height: 13px;
}

.loginMissingInfoModal .header img {
    width: auto;
    height: 100%;
}

/* ********************************************************************* */


.loginMissingInfoModal .body {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    gap: 20px;
    flex-wrap: wrap;
}


/* *********************************************************************** */


.loginMissingInfoModalWrapper .saveButton {
    background: #FA6342;
    border-radius: 100px;
    width: 200px;
    height: 30px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border: none;
    transition: .2s;
    cursor: pointer;
}


.loginMissingInfoModalWrapper .saveButton:disabled {
    background: #000000;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    border: none;
    transition: .2s;
    cursor: not-allowed;
}


@media screen and (max-width: 500px) {
    .loginMissingInfoModal {
        height: auto;
        max-height: unset;
    }

    .loginMissingInfoModal .body .labelAndInput {
        width: 100%;
    }

    .loginMissingInfoModalWrapper .saveButton {
        height: 45px;
        width: 100%;
        border-radius: 5px;
        margin-top: 15px;
    }
}