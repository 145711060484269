#modal-wrapper {
    width: 0;
    height: 50px;
    overflow: hidden;
    padding: 0;
    border-radius: 0;
    position: absolute;
    transform: translate(-50%, 0);
    top: 0px;
    left: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    opacity: 0;
    z-index: 999;
    overflow: hidden;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: none;
}

#modal-wrapper[class*="error"] {
    border-left: 7px solid #e98566;
}

#modal-wrapper[class*="success"] {
    border-left: 7px solid #b9c955;
}

#modal-wrapper[class*="error"] svg {
    fill: #e98566;
}

#modal-wrapper[class*="success"] svg {
    fill: #b9c955;
}

#modal-wrapper[class*="open"] {
    animation: modal-bounce-open .3s linear forwards;
    opacity: 1;
}

#modal-wrapper[class*="close"] {
    animation: modal-bounce-close 1s linear forwards;
    opacity: 1;
}

.modal-message {
    font-size: 20px;
    color: #616161;
    text-align: start !important;
    white-space: nowrap;
}

#modal-wrapper[class*="open"] .modal-message {
    animation: modal-message-fade-in .9s linear forwards;
}

#modal-wrapper svg {
    width: 35px;
}

@keyframes modal-bounce-open {
    0% {
        width: 0;
    }

    100% {
        top: 0px;
        padding: 0 90px;
        width: 500px;
    }
}

@keyframes modal-message-fade-in {
    0% {
        opacity: 0;
        transform: translateY(5px);
    }
    30% {
        opacity: 0;
        transform: translateY(5px);
    }
    50% {
        transform: translateY(0px);
    }
    100% {
        top: 0px;
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes modal-bounce-close {
    0% {
        top: 0px;
        width: auto;
        padding: 0 90px;
    }
    100% {
        top: -100%;
    }
}