#writerSearchPageWrapper {
    width: 100vw;
    height: 100%;
    background: #EDF2F6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
}

#writerSearchPageWrapper .container {
    width: 100%;
    max-width: 860px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 7px 0 7px;
    box-sizing: content-box;
    gap: 15px;
    overflow-y: auto;
}

#writerSearchPageWrapper .filterContainer {
    width: 100%;
    height: auto;
    padding: 15px 20px;
    background: #fff;
    border: 1px solid #EDF2F6;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    border-radius: 5px;
}


#writerSearchPageWrapper .ageSideWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 11px;
}

#writerSearchPageWrapper .ageInputWrapper {
    max-width: 60px;
}

#writerSearchPageWrapper .filterWrapper {
    width: 100%;
    max-width: 150px;
}

#writerSearchPageWrapper .filterWrapper.small {
    width: 100%;
    max-width: 90px;
}

#writerSearchPageWrapper .filterWrapper.small .selectedOptionWrapper {
    padding: 0 5px;
}

#writerSearchButton {
    width: 70px;
    height: 33px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FA6342;
    border: none;
    border-radius: 15px;
    cursor: pointer;
}

#writerSearchButton svg {
    fill: #fff;
}


.deleteSvg {
    background: transparent;
    padding: 3px;
    box-sizing: border-box;
    border-radius: 100%;
    transition: .2s;
}

.deleteSvg:hover {
    background: #cfcfcf;
    transition: .2s;
}

.filteredWritersListWrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}


@media screen and (max-width: 800px) {
    #writerSearchPageWrapper .container {
        /* padding: calc( 30px) 7px calc(30px + 40px + 15px) 7px; */
        box-sizing: border-box;
    }

    #writerSearchPageWrapper .filterContainer {
        flex-direction: column;
        justify-content: space-between;
    }

    #writerSearchPageWrapper .filterWrapper {
        width: 100%;
        max-width: unset;
    }
    #writerSearchPageWrapper .filterWrapper.small {
        width: 100%;
        max-width: unset;
    }
    #writerSearchPageWrapper .ageSideWrapper {
        width: 100%;
        max-width: unset;
    }
    #writerSearchButton {
        width: 100%;
        margin-top: 15px;
    }
    #writerSearchPageWrapper .filterWrapper.small .selectedOptionWrapper {
        padding: 0 20px;
    }
}