.simpleInputWrapper {
    width: 100%;
    /* max-width: 300px; */
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.simpleInputWrapper label {
    font-size: 13px;
    font-weight: 500;
}

.simpleInputWrapper .inputWrapper {
    width: 100%;
    height: 40px;
    background: #EDF2F6;
    border-radius: 4px;
    border: 1px solid #E4E4E4;
}

.simpleInput {
    width: 100%;
    height: 100%;
    padding: 12px 13px;
    border: none;
    background: transparent;
    color: #535165;
    font-size: 13px;
    font-weight: 500;
    outline-style: none;
    border-radius: inherit;
}

.simpleInput::placeholder {
    font-size: 13px;
}

.simpleInput:focus-within {
    outline-style: none;
}