#FAQPage {
    width: 100%;
    height: auto;
    min-height: 100%;
    background: #EDF2F6;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#FAQPage .header {
    width: 100%;
    height: 150px;
    background: #495B72;
    position: relative;
}


#FAQPage .header h1 {
    font-size: 40;
    font-weight: 400;
    color: #fff;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}


#FAQPage .header img {
    width: auto;
    height: 80px;
    max-height: 60%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
}



#FAQPage .body {
    width: 100%;
    height: calc(100% - 150px);
    max-width: 1000px;
    padding-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}

#FAQPage .body .titlesWrapper {
    background: #fff;
    padding: 17px 15px;
    width: 253px;
    min-width: 253px;
    height: auto;
    position: relative;
    border-radius: 5px;
    border: 1px solid #EDE9E9;
}

#FAQPage .body .firstRow {
    padding-bottom: 9px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

#FAQPage .body .titlesWrapper:before {
    content: "";
    width: 3px;
    height: 24px;
    background: #FA6342;
    position: absolute;
    top: 17px;
    left: 0;
    border-radius: 5px;
}

#FAQPage .body .titlesWrapper:after {
    content: "";
    width: 100%;
    height: 1px;
    background: #EDE9E9;
    position: absolute;
    top: calc(17px + 24px + 9px);
    left: 0;
    border-radius: 5px;
}

#FAQPage .titlesWrapper .firstRow h2 {
    font-size: 15px;
    font-weight: 400;
    color: #515365;
}

#FAQPage .titlesWrapper .titlesList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    padding: 18px 0;
}

#FAQPage .titlesWrapper .titlesList>div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

#FAQPage .titlesWrapper .titlesList p {
    font-size: 13px;
    color: #515365;
    cursor: pointer;
}

#FAQPage .titlesWrapper .titlesList .selected p {
    color: #FA6342;
}

#FAQPage .titlesWrapper .titlesList .selected path {
    fill: #FA6342;
}


#FAQPage .answerWrapper {
    width: 100%;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

#FAQPage .answerWrapper h1 {
    font-size: 24px;
    font-weight: 500;
    color: #515365;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

#FAQPage .answerWrapper p {
    font-size: 14px;
    font-weight: 400;
    color: #515365;
}



.addQuestionsModal {
    width: 100%;
    max-width: 1000px;
    height: 100%;
    max-height: 40%;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 17px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.addQuestionsModal .buttonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

#FAQPage .questionsList {
    width: 100%;
}

#FAQPage .questionsList .ql-editor {
    padding: 0;
}




@media screen and (max-width: 800px) {

    #FAQPage .header {
        width: 100%;
        height: 120px;
    }


    #FAQPage .body {
        width: 100%;
        height: calc(100% - 120px);
    }

    #FAQPage .header img {
        width: auto;
        height: auto;
        max-width: 100%;
    }
}


@media screen and (max-width: 600px) {

    #FAQPage .header {
        width: 100%;
        height: 85px;
    }


    #FAQPage .body {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 20px 20px;
    }

    #FAQPage .body .titlesWrapper {
        width: 350px;
        max-width: 100%;
    }

    #FAQPage .header h1 {
        width: max-content;
        font-size: 21px;
    }

}