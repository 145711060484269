.aboutContentEditForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 15px;
}

.aboutContentEditForm input,
textarea {
  width: 100%;
}

.contentEditButtonsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.contentEditButton {
  cursor: pointer;
  background: #fff;
  border: 1px solid #e4f3ed;
  border-radius: 5px;
  padding: 7px 15px;
  margin-top: 5px;
}

.quill {
  background: #fff;
}

.ql-container {
  background: #e9e9e9;
}


.ql-picker-options {
  max-height: 100px;
  overflow: auto;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: attr(data-value) !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before {
  color: red !important;
}

/* custom font bug fix for react-quill */

.ql-picker.ql-font {
  .ql-picker-item {
    font-size: 0;

    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font {
  .ql-active {
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

@font-face {
  font-family: Calibri;
  src: url("../assets/fonts/calibri-regular.ttf");
}

@font-face {
  font-family: Ubuntu;
  src: url("../assets/fonts/Ubuntu-Regular.ttf");
}

@font-face {
  font-family: Arial;
  src: url("../assets/fonts/arial.ttf");
}

@font-face {
  font-family: Tahoma;
  src: url("../assets/fonts/tahoma.ttf");
}

@font-face {
  font-family: NewRoman;
  src: url("../assets/fonts/times-new-roman.ttf");
}


@font-face {
  font-family: Verdana;
  src: url("../assets/fonts/verdana.ttf");
}


@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/Roboto-Regular.ttf");
}

.ql-picker.ql-font .ql-picker-label[data-value="Calibri"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Calibri"]::before {
  font-family: "Calibri";
  content: "Calibri" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Verdana"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Verdana"]::before {
  font-family: "Verdana";
  content: "Verdana" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Tahoma"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Tahoma"]::before {
  font-family: "Tahoma";
  content: "Tahoma" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="NewRoman"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="NewRoman"]::before {
  font-family: "NewRoman";
  content: "NewRoman" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before {
  font-family: "Arial";
  content: "Arial" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Ubuntu"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Ubuntu"]::before {
  font-family: "Ubuntu";
  content: "Ubuntu" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before {
  font-family: "Roboto";
  content: "Roboto" !important;
}

/* Set content font-families */
.ql-font-Roboto {
  font-family: "Roboto";
}
.ql-font-Calibri {
  font-family: "Calibri";
}

.ql-font-Verdana {
  font-family: "Verdana";
}
.ql-font-Ubuntu {
  font-family: "Ubuntu";
}

.ql-font-Tahoma {
  font-family: "Tahoma";
}

.ql-font-NewRoman {
  font-family: "times-new-roman.ttf";
}

.ql-font-Arial {
  font-family: "arial.ttf";
}