.postWrapper {
    width: calc(100% - 3px);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0px 0px 10px 0px;
    border-radius: 5px;
    padding: 10px 24px 0 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    scroll-snap-align: start;
}
.postWrapper.mostLiked {
    background-color: gold;
}
.postHeaderAndBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.letter-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: red;
    font-family: sans-serif;
}
.letter {
    color: #ffffff;
    text-transform: capitalize;
    font-family: sans-serif;
    font-weight: 600;
    font-size: 18px;
}

.letter.big {
    font-size: 100%;
}



.postHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 25px;

}

.postHeader .profilePhoto>img {
    height: 100%;
    width: 100%;
}

.post-header-userNameAndPostTime {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.post-header-user-name {
    cursor: pointer;
    transition: .2s;
    color: #FA6342;
    font-size: 15px;
    text-transform: capitalize;
    
}

.post-br {
    width: 90%;
    height: 2px;
    background-color: ;
}

.post-header-user-name:hover {
    color: #ffbcad;
    transition: .2s;
}

.post-header-post-time {
    color: #515365;
    font-size: 12px;
    font-weight: 300;
    line-height: 30px;
}

.postBody {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 20px;
}

.postParagraph {
    white-space: pre-line;
    word-break: break-word;
    user-select: text;
    font-size: 14px;
    color: #515365;
    margin-top: 14px;
    line-height: 18px;
    max-height: 108px;
    overflow-y: auto;
    width: 100%;
}

.postParagraph.fullHeight {
  
}






.postFooter {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px 0 5px 0;
    gap: 15px;
    border-top: 1px solid #e5e5e5;
}

.likeAndDissLikeWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.reportPostButton {
    background: transparent;
    border: none;
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-left: 15px;
}

.reportPostButton > svg {
    width: 100%;
    height: 100%;
    fill: #000;
}

.reportPostButton:hover > svg {
    fill: #000;
}

.postFooter .likeButton {
    background-color: transparent;
    border: none;
    height: 20px;
    cursor: pointer;
}

.showMoreButton {
    cursor: pointer;
    color: #515365;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
    user-select: none;
    position: absolute;
    bottom: 15px;
    right: 15px;
}
.postFooter .dissLikeButton {
    background-color: transparent;
    border: none;
    height: 20px;
    cursor: pointer;
}

.postFooter .dissLikeButton img {
    transform: translateY(5px);
}

.likeButtonAndCountWrapper .count {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%);
} 
/* 
.likeButton:nth-child(1) {
    display: none;
} */

.postFooter .likeButton img {
    height: 100%;
}


.postFooter .dissLikeButton img {
    height: 100%;
}

.postFooter .likeButtonAndCountWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    position: relative;
}


.tooltip {
    width: 150px;
    height: 100px;
    overflow: auto;
    background-color: rgb(167, 167, 167);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    display: none;
}

.likeButtonAndCountWrapper:hover>.tooltip {
    display: block;
}