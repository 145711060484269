#dummyPersonInfoModal {
    width: 40px;
    height: 100%;
    background: #fff;
    position: absolute;
    top: 0;
    right: 0;
}

.messagesWrapper {
    position: absolute;
    width: calc(100% - 70px);
    height: calc(100% - 66px);
    transform: translate(0%, 0%);
    top: 66px;
    left: 70px;
    display: flex;
    flex-direction: row;
    z-index: 90;
    overflow: hidden;
}

.messagesWrapper.close {
    display: none;
}

#sendMassageWrapper .EmojiPickerReact.epr-main {
    transform: translate(50%, -50px);
}

#messagesHeader {
    width: 100%;
    height: auto;
    min-height: 66px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-bottom: 1px solid #EAEAEA;
    z-index: 3;
    padding: 0px 15px;
}

.selectedPersonAndBack {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#messagesGoBackButton {
    background: transparent;
    border: none;
    width: auto;
    height: 35px;
}

#messagesGoBackButton>svg {
    width: 100%;
    height: 100%;
    fill: #FA6342;
}

#messagesGoBackButton>svg>path {
    width: 100%;
    height: 100%;
}

/* 
#messagesHeader>button {
    background: transparent;
    border: none;
    width: 35px;
    height: 35px;
    cursor: pointer;
}

#messagesHeader>button>svg {
    width: 100%;
    height: 100%;
    fill: var(--buttonColor);
} 
*/

#messagesBody {
    width: calc(71% - 40px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
}



.contactSide {
    width: 29%;
    height: 100%;
    background: #fff;
    z-index: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

#contactSideHeader {
    width: 100%;
    height: 107px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 29px;
    gap: 13px;
}

#contactSideHeader .row-one {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#contactSideHeader .row-one h1 {
    font-size: 18px;
    font-weight: 500;
    color: #535165;
}

#contactSideHeader .row-one svg {
    width: 18px;
    height: 18px;
    fill: #535165;
}

#contactSideHeader .row-two {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contextSide {
    width: calc(100% - 0px);
    height: calc(100% - 66px);
    background: #fbfcfc;
    box-shadow: 5px 0px 15px -15px inset;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px 0px; */
}

.messagesListWrapper {
    width: 100%;
    height: calc(100% - 70px);
    overflow-y: auto;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 10px 22px;
}

.messagesListWrapper::-webkit-scrollbar {
    display: none;
}


.messageUserRow {
    width: calc(100% - 9px);
    height: 65px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    padding: 13px 18px 13px 13px;
    border-bottom: 1px solid #EAEAEA;
    cursor: pointer;
    transition: .3s;
    margin: 0;
}

.nameAndLastMessageWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.messagerName {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: bold;
    color: #FA6342;
}

.lastMessageAndWhenWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.lastMessageAndWhenWrapper p {
    font-size: 13px;
    color: #515365;
}

.lastMessage {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nameAndIsOnline {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 15px;
}

.nameAndIsOnline p {
    color: #515365;
    text-transform: capitalize;
}

.nameAndIsOnline p:nth-child(1) {
    font-size: 16px;
    font-weight: 500;
}

.nameAndIsOnline p:nth-child(2) {
    font-size: 13px;
}

.messageUserRow:hover {
    background: #EAEAEA;
}

.unReadedMessagesCount {
    background: #FA6342;
    border-radius: 100%;
    width: 21px;
    height: 21px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.unReadedMessagesCount p {
    font-size: 11px;
    color: #fff
}


.messageUserRow.selected {
    transition: .3s;
    background: #EAEAEA;
}



.messageBox {
    background-color: #DAE7F1;
    width: fit-content;
    padding: 8px 8px;
    border-radius: 5px;
    position: relative;
    min-width: 200px;
    max-width: 70%;
    position: relative;
}

.message-text {
    color: #838195;
    word-break: break-word;
    font-size: 13px;
}

.message-date {
    font-size: 13px;
    color: #515365;
    text-align: end;
}

.messageWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}

.messageWrapper.left {
    place-self: end;
    flex-direction: row-reverse;
}

.messageBox.left {
    background: #DBF4FD;
}

.mesageBoxAndDateWrapper {
    width: min-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 6px;
}

.messageCheckAndDateWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 6px;
}

.messageBox>svg {
    content: "";
    position: absolute;
    top: 9px;
    left: 1px;
    transform: translate(-100%, 0);
    fill: #202c33c7;
}


.messageBox.left>svg {
    content: "";
    position: absolute;
    top: 9px;
    left: unset;
    right: 1px;
    transform: translate(100%, 0);
    fill: #0f5e79;
}


#sendMassageWrapper {
    width: 100%;
    height: auto;
    min-height: 72px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    border-top: 2px solid #BABABA;
    gap: 5px;
    padding: 0 21px 0 0;
}

/* #sendMassageWrapper textarea {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    resize: none;
    border: none;
    outline-style: none;
    background: transparent;
    padding: 0px 10px;
    box-shadow: rgba(0, 0, 0, 0.2) -0px 1.95px 2.6px;
    padding: 7px 15px 0px 15px;
    overflow: visible;
} */


.messageButtonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    gap: 10px;
}

#sendMessageButton {
    width: 26px;
    height: 26px;
    background: transparent;
    border-radius: 50%;
    border: none;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

#sendMessageButton svg {
    width: 100%;
    height: 100%;
}

.emojiButton {
    border: none;
}

.emojiButton svg {
    fill: #FFC033;
}

#sendMassageWrapper button:active {
    transition: all .2s ease-in-out;
    background: #2873b8;
}

#sendMassageWrapper button:active>svg {
    fill: #e3f2ff;
}


/* ============================ SMALL MESSAGES SIDE ============================ */

#SmallMessagesWrapper {
    position: relative;
}

#SmallMessagesWrapper .unReadedMessagesCount {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 14px;
    height: 14px;
    transform: translate(5px, 5px);
    background: #FA6342;
}

#SmallMessagesWrapper .unReadedMessagesCount span {
    font-size: 11px;
    color: #fff;
}

#smalMessagesButton {
    width: 40px;
    height: 40px;
    border: none;
    cursor: pointer;
    transition: .3s;
    background: transparent;
    border-radius: 100px;
    padding: 5px;
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#smalMessagesButton:hover {
    background: #929292;
    transition: .3s;
}

#smalMessagesButton>svg>path {
    fill: #fff !important;

}

#smalMessagesButton:hover>svg>path {
    fill: #fff !important;
    transition: .3s;
}

#smalMessagesButton svg {
    width: 20px;
    height: 23px;
}


#smalMessagesButton svg path {
    fill: #585858;
}


#smalMessagesButton svg rect {
    fill: #e2e2e2;
}



@media only screen and (max-width: 1200px) {
    #messagesBody {
        width: calc(100% - 311px - 40px);
    }

    .contactSide {
        min-width: 311px;
    }
}


@media only screen and (max-width: 800px) {
    .messagesWrapper {
        width: 100%;
        height: calc(100% - 112px - 70px);
        top: 112px;
        left: 0;
        transform: translate(0, 0%);
        flex-direction: column;
    }


    #messagesBody {
        width: 100%;
    }

    .contactSide {
        width: 100%;
    }

    .contextSide {
        width: 100%;
    }

    .infoButton {
        background: transparent;
        border: none;
        padding: 0;
        margin: 0;
        width: 30px;
        height: 30px;
    }
    .infoButton svg {
        width: 100%;
        height: 100%;
    }
}