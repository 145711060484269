.contentSideHeader {
    height: 41px;
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.contentSideHeader>h1 {
    color: #fa6342;
    font-size: 14px;
}

.contentSideHeader>button {
    width: 40px;
    height: 14px;
    border: none;
    background: transparent;
    position: relative;
}

.contentSideHeader>button::before {
    content: "\00d7";
    display: inline-block;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 30px;
    font-weight: 100;
    color: #bbb;
}

@media only screen and (max-width: 1200px) {
    #contentSideWrapper.desktop {
        display: none;
    }
}


@media only screen and (min-width: 1200px) {
    #contentSideWrapper {
        background: transparent;
        cursor: default;
        align-items: flex-start;
        position: fixed;
    }

    .secondCol {
        width: auto;
        height: auto;
    }

    .sub-title-wrapper {
        height: auto;
        cursor: pointer;
    }

    .content-wrapper {}

    .content-wrapper.active {}

    /* .content-wrapper.active .content-title {
    color: #fff;
    font-size: 13px;
} */

    .firstCol {
        border-right: 1px solid rgb(214, 214, 214);
    }

}