.SimpleSelectInputWrapper {
    width: 100%;
    height: 40px;
    background: #EDF2F6;
    border: 1px solid #E4E4E4;
    position: relative;
    user-select: none;
    cursor: pointer;
    border-radius: 5px;
}

.selectedOptionWrapper {
    width: 100%;
    height: 100%;
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.selectedOptionWrapper .svgsWrapper {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}

.selectedOptionWrapper p {
    font-size: 13px;
    color: #535165;
    text-transform: capitalize;
}

.optionsWrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    max-height: 250px;
    overflow-y: auto;
    transform: translateY(100%);
    background: #fff;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: .3s;
}

.optionsWrapper .option {
    width: 100%;
    height: 35px;
    min-height: 35px;
    border: none;
    background: #f3f6f8;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px;
    font-size: 13px;
    color: #535165;
    user-select: none;
    cursor: pointer;
    transition: .3s;
    text-transform: capitalize;
}

.optionsWrapper .option:hover {
    background: #e5eaee;
    transition: .3s background;
    /* color: #fff; */
}