#homePage {
    background-color: var(--homeBackgroundColor);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}


.home-body {
    width: 100%;
    min-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow-x: hidden;
    /* padding-bottom: 60px; */
}

#ScrollViewAndSendPost {
    width: 100%;
    /* for prevent scrolling all over the page*/
}

.postsScrollView {
    max-width: 100%;
    width: 100%;
}

#postSideWrapper {
    width: 100%;
}

.postsFooter {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.postsHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}




.aboutAndPostsWrappper {
    max-width: 733px;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 22px 0;
}

.dummy {
    width: 300px;
    height: 500px;
    background: #808080;
    position: fixed;
    top: 85px;
    right: 10px;
    border-radius: 5px;
    background: url("./assets/dummy.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media only screen and (max-width: 1400px) and (min-width: 800px) {
    .home-body {
        padding-top: 150px;
    }

    .dummy {
        width: 100%;
        height: 150px;
        top: 70px;
        right: 0;
        border-radius: 0px;
        background-position: center;
        background-repeat: repeat-x;
        background-size: contain;
    }
}

@media only screen and (max-width: 800px) {
    #homePage {
    }
    .aboutAndPostsWrappper {
        width: 100vw;
        max-width: unset;
        padding: 20px;
        padding-bottom: calc(80px);
    }

    #ScrollViewAndSendPost {
        width: 100%;
    }

    /* GEÇİCİ */
    .dummy {
        display: none;
    }

    .home-body {
    }
}


@media only screen and (max-width: 1500px) and (min-width: 1200px) {

    #postSideWrapper {
        width: 100%;
    }
}