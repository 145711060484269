#contentSideWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow: hidden;
    height: auto;
    position: fixed;
}

#contentSideWrapper.none {
    display: none;
}

#contentSideWrapper .firstCol {
    background-color: transparent;
    width: 240px;
    border-collapse: collapse;
}

#contentSideWrapper .secondCol {
    background-color: rgb(49, 133, 206);
    flex: 1;
    height: 100%;
    width: 265px;
    min-width: 265px;
}
#contentSideWrapper > .scrollX {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: hidden;
}


.content-wrapper {
    max-height: 41px;
    width: 212px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 15px;
    cursor: pointer;
    text-transform: capitalize;
}

.sub-title-wrapper>.content-wrapper {
    background-color: rgb(255, 255, 255);
}


.content-wrapper:hover {
    /* background: #9fcaf0; */
    transition: .3s;
}
.content-wrapper .content-title {
    color: #fff;
    font-size: 13px;
}
.content-wrapper:hover .content-title {
    /* color: #fff; */
}

.content-wrapper.selected {
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, var(--buttonColor) 0%, var(--buttonColor) 2%, #fff 2%);
    -webkit-transition: background 0.2s linear;
}

.content-wrapper.selected .content-title {
    color: #000 !important;
}

.content-icon {
    height: 100%;
    width: 40px;
}

.sub-title-wrapper {
    height: 0;
    overflow-y: hidden;
    transition: .3s;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}

.sub-title-wrapper.open {
    transition: .3s;
    overflow-y: hidden;
}


@media only screen and (max-width: 800px) {

    #contentSideWrapper > .scrollX {
        width: 100%;
    }
}
