.whichsideButtonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: min-content;
    gap: 11px;
    margin: 10px 0;

}

.whichSideButton {
    padding: 6px;
    /* padding: 5px 0 5px 15px; */
    border-radius: 100px;
    box-sizing: border-box;
    width: 35px;
    height: 35px;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    cursor: pointer;
    gap: 15px;
    background: #7A8594;
    border: none;
    color: #fff;
    font-weight: 500;
    transition: .3s;
}

.whichSideButton svg {
    width: 100%;
    height: 100%;
}


.whichSideButton.feMaleSide {
    background: #FC8DCA;
    border: 1px solid #fff;
    color: #fff;
}

.whichSideButton.maleSide {
    background: #AAECFC;
    border: 1px solid #515365;
    color: #515365;
}

.whichSideButton.maleSide svg path {
    fill: #515365;
}
