#acount-seting {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#acount-seting>.container {
    width: 100%;
    height: 425px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #EDE9E9;
    border-radius: 5px;
}


#acount-seting>.container .left {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    border-right: 2px dashed #C4D6ED;
}


#acount-seting>.container .leftWrapper {
    width: 100%;
    height: 100%;
    max-width: 190px;
    padding: 35px 25px;
    padding-right: 0;
}

.iconAndTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 11px;
}

#acount-seting>.container .inputsRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 68px;
    width: 100%;
}

#acount-seting>.container .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 9px;
}

#acount-seting>.container .body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
}


#acount-seting>.container .right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px;
    background-color: #fff;
    border: 1px solid #EDE9E9;
    border-left: none;
    border-radius: 5px;
}

#acount-seting>.container .right .header h1 {
color: #515365;
font-size: 20px;
font-weight: 500;

}

#acount-seting>.container .right .header p {
    color: #515365;
font-size: 13px;
font-weight: 300;

}

#acount-seting>.container>.col:nth-child(1) {
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 30px 15px;
}

#acount-seting>.container>.row:nth-child(1)::after {
    content: "";
    width: calc(100% - 30px);
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 50%;
    background: #dbdbdb;
    transform: translate(-50%, -0%);
}


#acount-seting>.container>.row:nth-child(2) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    padding: 30px 30px;
}

.setting-row {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
}

.setting-row.passwordSide {
    width: auto;
}
.passwordAndConfirmWrapper .simpleInputWrapper {
    max-width: 300px;
    width: 300px;
}
.setting-row.dateSide {
    width: auto;
}

.setting-row div:has(.SimpleSelectInputWrapper):not(div:has(.label)) {
    width: 100%;
}

.label {
    font-size: 13px;
    min-width: 120px;
    color: #515365;
    font-weight: 500;
}

.border-bottom-input-wrapper {
    position: relative;
    width: 200px;
}

.border-bottom-input-wrapper>svg {
    position: absolute;
    transform: translate(0%, -50%);
    top: 50%;
    right: 0%;
    width: auto;
    height: 20px;
    cursor: pointer;
    border-radius: 100px;
    padding: 2px;
}

.border-bottom-input-wrapper>svg {}

.border-bottom-input-wrapper>svg:hover {
    background: #e3f2ff88;
}

.emailAndPasswordNecessaryWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;
}

.passwordAndConfirmWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

.emailAndPasswordNecessaryWrapper .border-bottom-input-wrapper:nth-child(2) {
    width: 50%;
}

.emailAndPasswordNecessaryWrapper .border-bottom-input-wrapper.passive {
    opacity: 0;
    z-index: -1;
    transition-delay: 1ms;
    /* transform: translateY(-100%); */
    margin-top: -35px;
    transition: .3s;
}

.emailAndPasswordNecessaryWrapper .border-bottom-input-wrapper.active {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    transition: .3s;
}

.border-bottom-input-wrapper>input {
    width: 100%;

}

.border-bottom-date-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    background: #EDF2F6;
    border-radius: 3px;
    min-width: 200px;
    height: 40px;
    padding: 0 2px;
    border: 1px solid #E4E4E4;
    padding: 0 10px;
}
.border-bottom-date-wrapper > label {
    position: absolute;
    top: 0;
    left: 8px;
    transform: translateY(-50%);
    font-size: 14px;
    color: #fff;
    padding: 0 7px;
}
.border-bottom-date-wrapper svg {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 11px;
}

.border-bottom-date-wrapper .border-bottom-input-wrapper.small span {
    font-size: 22px;
    color: #dbdbdb;
}

.border-bottom-input-wrapper.small {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

/* .border-bottom-input-wrapper::after {
    content: "";
    width: calc(100%);
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, calc(-50% + 1px));
    background: #797979;
    animation: input-focus-animation-reverse .3s linear forwards;

    background: linear-gradient(90deg, rgba(119, 119, 119, 1) 50%, rgba(255, 0, 0, 1) 50%, rgba(119, 119, 119, 1) 50%);
} 
*/

.setting-input {
    background: transparent;
    border: none;
    position: relative;
    /* border-bottom: 2px solid rgba(209, 209, 209, 0.733); */
    padding: 4px 0;
    text-align: center;
    color: #454545;
    max-width: 30px;
}
.setting-input.active {
    max-width: unset;
    background: #EDF2F6;
    border: 1px solid #E4E4E4;
}

.setting-input:focus-within {
    outline: none;
}


.border-bottom-input-wrapper:has(.setting-input.error)::after {
    outline: none;
    animation: input-focus-animation .2s linear forwards;
    background: red;
}

.border-bottom-input-wrapper:has(.setting-input:focus-within)::after {
    outline: none;
    animation: input-focus-animation .2s linear forwards;
    background: var(--buttonColor);
}

.edit-button {
    background: #FA6342;
    border: none;
    padding: 7px 5px 8px 7px;
    border-radius: 100%;
    color: var(--inputBorderColor);
    transition: .3s;
    cursor: pointer;
    width: 32px;
    height: 32px;
    position: absolute;
    bottom: 20px;
    right: -5px;
    transform: translate(100%, 50%);
}

.edit-button svg {
    width: 100%;
    height: 100%;
}

.edit-button.silence:hover {
    background: var(--inputLabelColor);
    border: none;
    padding: 8px 18px;
    border-radius: 100px;
    color: var(--inputBorderColor);
    transition: .3s;
    cursor: progress;
}

.edit-button:hover {
    background: var(--inputBorderColor);
    color: var(--inputLabelColor);
    transition: .3s;
}


.selections {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: var(--inputLabelColor);
    position: absolute;
    bottom: 4px;
    transform: translate(0%, 100%);
    width: 200px;
    max-height: calc(34px*4);
    overflow: hidden;
}

.selection {
    padding: 5px 10px;
    width: 100%;
    max-height: 34px;
    cursor: pointer;
}

#settings-title {
    font-size: 30px;
    color: #145491;
}

.selection:hover {
    background: #c7e4ff;
}

@keyframes password-fade-in {
    0% {
        transform: translateY(0, -100%);
    }

    100% {
        transform: translateY(0, 0);
    }
}

@keyframes input-focus-animation {
    0% {
        width: 0
    }

    100% {
        width: 100%
    }
}

@keyframes input-focus-animation-reverse {
    0% {
        width: 100%
    }

    100% {
        width: 0
    }
}

@media screen and (max-width: 600px) {
    #acount-seting>.container {
        width: 97%;
        height: 70%;
    }

    #acount-seting>.container>.row:nth-child(2) {
        gap: 20px;
        padding: 30px 15px;

    }

    .setting-row {
        flex-wrap: wrap;
    }

    .edit-button {
        /* padding: 0; */
        font-size: 10px;
    }

    .label {
        font-size: 12px;
        min-width: 100%;
    }

    .inputsRow {
        flex-direction: column !important;
        gap: 15px !important;
        width: calc(100% - 32px) !important;
    }

    #acount-seting>.container .right {
        gap: 15px;
        padding: 25px 15px ;
    }
    .setting-row {
        width: calc(100%);
    }
    .setting-row.dateSide {
        width: calc(100%);
    }

    .border-bottom-date-wrapper {
        width: 100%;
    }

}

@media screen and (max-width: 1000px) {
    #acount-seting>.container {
        flex-direction: column;
    }
    #acount-seting>.container .left {
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        padding: 20px 0;
        border-right: none;
        border-bottom: 2px dashed #C4D6ED;
    }
    #acount-seting>.container .leftWrapper {
        max-width: unset;
        height: min-content;
        padding: 0;
    }

    .passwordAndConfirmWrapper .simpleInputWrapper {
        width: auto;
    }

    #acount-seting>.container .right {
        height: auto;
    }
}